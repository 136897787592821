import React, { useState, useEffect } from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { useOktaAuth } from "@okta/okta-react";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import WelcomeMessage from "../pages/welcome/Welcome";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Tooltip from "@material-ui/core/Tooltip";
import HotelIcon from "@material-ui/icons/Hotel";
import Divider from "@material-ui/core/Divider";
import ScienceIcon from "@mui/icons-material/Science";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import GroupIcon from "@material-ui/icons/Group";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function SwipeableEdgeDrawer(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example

  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };
  if (!authState) {
    return null;
  }
  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };
  return (
    <>
      <div>
        {!authState.isPending && !authState.isAuthenticated && (
          <WelcomeMessage />
        )}
      </div>
      {authState.isAuthenticated && (
        <Root>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(30% - ${drawerBleeding}px)`,
                overflow: "visible",
              },
            }}
          />

          <AppBar
            variant="fluid"
            style={{
              background:
                "linear-gradient(-45deg, rgb(52, 181, 191) 30%, rgb(6, 82, 92) 100%)",
            }}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>

              <img
                src={process.env.PUBLIC_URL + "/CHicon.png"}
                width="35"
                height="35"
                className="d-inline-block align-top"
                alt=""
              ></img>
              <Typography
                className="navbar-brand p-2"
                style={{ fontSize: "18px" }}
              >
                CyncHealth{" "}
                <Typography style={{ fontSize: "12px" }}>
                NEBRASKA DASHBOARD
                </Typography>
              </Typography>
              <span className="example-fill-remaining-space"></span>

              <Button
                variant="outlined"
                color="inherit"
                id="logout-button"
                onClick={logout}
                endIcon={<PowerSettingsNewIcon />}
              >
                {" "}
                Logout
              </Button>
            </Toolbar>
          </AppBar>

          <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <StyledBox
              sx={{
                boxShadow: 1,
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: "visible",
                right: 0,
                left: 0,
              }}
            >
              <Puller />
              <Typography sx={{ p: 2, color: "text.secondary" }}>
                More Options
              </Typography>
            </StyledBox>
            <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: "100%",
                overflow: "auto",
              }}
            >
              <List>
                <Link
                  to="/menu"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="Menu"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> Menu</ListItemText>
                  </ListItem>
                </Link>

                {handleGroupAuth("Nebraska Bed Capacity Dashboard Users") ? (
                  <Link
                    to="/bedcapacity"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title="Live Bed Capacity"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <HotelIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText>
                        {" "}
                        Live Bed Capacity{" "}
                        <span className="livebadge pulsate ">NRT</span>
                      </ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}

                {handleGroupAuth("Nebraska Labs Dashboard Users") ? (
                  <Link
                    to="/labs"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title="Labs Dashboard"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <ScienceIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText>Labs Dashboard {" "}
                      <span className="livebadge pulsate ">NRT</span>
                      </ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}

                {handleGroupAuth("Nebraska ADT Dashboard Users") ? (
                  <Link
                    to="/adtdashboard"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title="ADT Dashboard"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <BlurOnIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText> ADT Dashboard</ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}

                {handleGroupAuth(
                  "Nebraska Covid19 Patients Dashboard Users"
                ) ? (
                  <Link
                    to="/covid19patients"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title="Covid-19 Patients"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <GroupIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText> Covid-19 Patients</ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}

                {handleGroupAuth(
                  "Nebraska Capacity Forecasting Dashboard Users"
                ) ? (
                  <Link
                    to="/capacityforecasting"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title=" Capacity Forecasting"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <TrendingUpIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText> Capacity Forecasting</ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}

                {handleGroupAuth("Nebraska PPE Dashboard Users") ? (
                  <Link
                    to="/ppedashboard"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title="PPE Dashboard"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <AddLocationIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText> PPE Dashboard</ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}

                {handleGroupAuth("Nebraska Reports Dashboard Users") ? (
                  <Link
                    to="/reports"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title="Reports"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <AssessmentIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText> Reports</ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}

                {handleGroupAuth("Nebraska Ad-hoc Reports Dashboard Users") ? (
                  <Link
                    to="/adhocreports"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title="Ad-Hoc Reports"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <EqualizerIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText> Ad-Hoc Reports</ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}
                <Divider />
                {handleGroupAuth("Admins Nebraska Covid Dashboard") ? (
                  <Link
                    to="/admin"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title="Admin Panel"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <AdminPanelSettingsIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText> Admin Panel</ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}

                <Link
                  to="/profile"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="Menu"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> Profile </ListItemText>
                  </ListItem>
                </Link>

                <Link
                  to="/contactus"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="Menu"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> Support </ListItemText>
                  </ListItem>
                </Link>
              </List>
            </StyledBox>
          </SwipeableDrawer>
        </Root>
      )}
    </>
  );
}

export default SwipeableEdgeDrawer;
