import React, { useMemo, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Covid19HospitalizationByCity from "./Covid19HospitalizationByCity";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { useOktaAuth } from "@okta/okta-react";
import NotFound from "../../Alerts/NotFound";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "rgb(8,87,97)",
  },
  text: {
    marginLeft: "-20px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
  },
});

export default function AdHocReports() {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const getData = () => {
    (async () => {
      const result = await fetch("adhocTestData.json", {
        method: "GET",
        headers: {
          // Authorization:'weoifjg0924gjwgj',
          "Content-Type": "application/json",
        },
      });
      const names = await result.json();
      setData(names);
    })();
  };
  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };

  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
      updateTime();
    }, 100000);
    return () => clearInterval(interval);
  }, []);


  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };
  
  const columns = useMemo(
    () => [
      {
        Header: "Patient City",
        accessor: "PatientCity",
      },
      {
        Header: "Hospital Name",
        accessor: "HospitalName",
      },
      {
        Header: "Active Hospitalization",
        accessor: "ActiveHospitalization",
      },
      {
        Header: "Hospitalization Ever",
        accessor: "HospitalizationEver",
      },
      {
        Header: "Current ICU",
        accessor: "CurrentICU",
      },
    ],
    []
  );

  if (handleGroupAuth("Nebraska Ad-hoc Reports Dashboard Users")) {
  return (
    <div>
      <div className="mt-5 pt-4">
        <ListItem className={classes.root}>
          <ListItemIcon>
            <InsertChartIcon fontSize="large" className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography>
            <span className={classes.text}>Ad-Hoc Reports</span>
            <div className="d-inline">
              <span className=" ml-3"> Last Updated : {updateTime()}</span>
            </div>
          </ListItemText>
        </ListItem>
      </div>
      <Paper elevation={2} style={{ padding: "8px", marginTop: "25px" }}>
        <Covid19HospitalizationByCity columns={columns} data={data} />
      </Paper>
      <div className="alert alert-light mt-4" role="alert">
        Disclaimer: Disclosure of identifiable health information is made
        pursuant to Nebraska Executive Order No. 20-15 and the federal HHS
        <a href="https://www.hhs.gov/sites/default/files/notification-enforcement-discretion-hipaa.pdf?language=es">
          Notification of Enforcement Discretion under HIPAA
        </a>
        , as such use is made to individuals who are in a position to prevent
        and lessen a serious and imminent threat to the health and safety of a
        person or the public.
      </div>
    </div>
  )} else {
    return (<NotFound/>)
  }
}
