export const hospitalTypes = [
  "Pediatric Hospital",
  "Critical Access Hospital",
  "Short Term Acute Care Hospital",
  "Rehabilitation Hospital",
];

export const coalitionRegions = [
  "PRMRS",
  "SENHCC",
  "TRIMRS",
  "OMHCC",
  "RROMRS",
  "NPHCC",
];
