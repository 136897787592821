import React from "react";
import Delayed from "../services/Delayed";

export default function NotFound() {
  return (
    <div className="mt-5 pt-4">
      <Delayed waitBeforeShow={1000}>
        <h2> Access Restricted</h2>
        <p>Please reach out to your adminsitrator for access!!</p>
      </Delayed>
    </div>
  );
}
