import React, { Component } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./styles.css";
import { Grid } from "@mui/material";
import LabsSampleMap from "./LabsSampleMap";
import LabsTopSixCounties from "./LabsTopSixCounties";
import LabsAgeGenderDist from "./LabsAgeGenderDist";
import LabsRaceDist from "./LabsRaceDist";
import LabsEthnicityDist from "./LabsEthnicityDist";
import { Box } from "@material-ui/core";

const GridLayout = WidthProvider(Responsive);

export default class MySecondGrid extends Component {
  state = {
    currentBreakpoint: "",
    layouts: storedLayouts,
  };

  static defaultProps = {
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    className: "layout",
    rowHeight: 50,
    isResizable: false,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    // compactType: "horizontal"
  };
  stopPropagation = (event) => {
    event.stopPropagation();
  };
  onLayoutChange = (layout, layouts) => {
    this.setState({ layouts });
  };

  onBreakpointChange = (breakpoint) => {
    this.setState({ currentBreakpoint: breakpoint });
  };

  render(props) {
    return (
      <main>
        {/* <GridLayout
            {...this.props}
            draggableHandle=".drag-handle"
            layouts={this.state.layouts}
            onLayoutChange={(layout, layouts) =>
              this.onLayoutChange(layout, layouts)
            }
            onBreakpointChange={this.onBreakpointChange}
          > */}
        <Box sx={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={6}>
              <div
                className="card-labs"
                style={{
                  backgroundColor: "white",
                  margin: "10px",
                  padding: "5px",
                }}
              >
                <LabsSampleMap
                  data={this.props.data}
                  dataType={this.props.dataType}
                />
              </div>
            </Grid>
            {/* <div  className="card-labs"
          style={{ backgroundColor: "white", marginRight: "10px", width:"400px" }}>
              Top 6 Counties{" "}
            
              <LabsTopSixCounties data={this.props.data} dataType={this.props.dataType}/>
            </div>
   */}

            <Grid item xs={6}>
              <div
                className="card-labs"
                style={{
                  backgroundColor: "white",
                  margin: "10px",
                  padding: "5px",
                }}
              >
                <LabsAgeGenderDist
                  data={this.props.data}
                  dataType={this.props.dataType}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="card-labs"
                style={{
                  backgroundColor: "white",
                  margin: "10px",
                  padding: "5px"
                }}
              >
                <LabsRaceDist
                  data={this.props.data}
                  dataType={this.props.dataType}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="card-labs"
                style={{
                  backgroundColor: "white",
                  margin: "10px",
                  padding: "5px"
                }}
              >
                <LabsEthnicityDist
                  data={this.props.data}
                  dataType={this.props.dataType}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        {/*             
          </GridLayout> */}
      </main>
    );
  }
}

const storedLayouts = {
  lg: [
    {
      i: "OrderedVsCompleted",
      w: 10,
      h: 6,
      x: 0,
      y: 0,
      minH: 4,
      minW: 4,
      static: true,
    },
    {
      i: "TotalPatientsTestedPositive",
      w: 10,
      h: 6,
      x: 6,
      y: 0,
      minH: 7.5,
      minW: 4,
      static: true,
    },
    { i: "MapComponent", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "TopSixCounties", w: 6, h: 6, x: 6, y: 0, minH: 6, minW: 3 },
    { i: "LabVsPositive", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "AgeGenderDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
    { i: "RaceDist", w: 6, h: 8, x: 0, y: 0, minH: 8, minW: 3 },
  ],
  md: [
    {
      i: "OrderedVsCompleted",
      w: 6,
      h: 8,
      x: 0,
      y: 0,
      minH: 4,
      minW: 4,
      static: true,
    },
    {
      i: "TotalPatientsTestedPositive",
      w: 6,
      h: 8,
      x: 6,
      y: 0,
      minH: 7.5,
      minW: 4,
      static: true,
    },
    { i: "MapComponent", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "TopSixCounties", w: 6, h: 6, x: 6, y: 0, minH: 6, minW: 3 },
    { i: "LabVsPositive", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "AgeGenderDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
    { i: "RaceDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
  ],
  sm: [
    {
      i: "OrderedVsCompleted",
      w: 6,
      h: 8,
      x: 0,
      y: 0,
      minH: 4,
      minW: 4,
      static: true,
    },
    {
      i: "TotalPatientsTestedPositive",
      w: 6,
      h: 8,
      x: 6,
      y: 0,
      minH: 7.5,
      minW: 4,
      static: true,
    },
    { i: "MapComponent", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "TopSixCounties", w: 6, h: 6, x: 6, y: 0, minH: 6, minW: 3 },
    { i: "LabVsPositive", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "AgeGenderDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
    { i: "RaceDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
  ],
  xs: [
    { i: "OrderedVsCompleted", w: 4, h: 8, x: 0, y: 0 },
    { i: "TotalPatientsTestedPositive", w: 6, h: 8, x: 0, y: 0 },
    { i: "MapComponent", w: 4, h: 6, x: 0, y: 0 },
    { i: "TopSixCounties", w: 4, h: 6, x: 0, y: 0 },
    { i: "LabVsPositive", w: 4, h: 8, x: 0, y: 0 },
    { i: "AgeGenderDist", w: 4, h: 8, x: 0, y: 0 },
    { i: "RaceDist", w: 4, h: 8, x: 0, y: 0 },
  ],
  xxs: [
    { i: "OrderedVsCompleted", w: 4, h: 8, x: 0, y: 0 },
    { i: "TotalPatientsTestedPositive", w: 6, h: 8, x: 0, y: 0 },
    { i: "MapComponent", w: 4, h: 6, x: 0, y: 0 },
    { i: "TopSixCounties", w: 4, h: 6, x: 0, y: 0 },
    { i: "LabVsPositive", w: 4, h: 9, x: 0, y: 0 },
    { i: "AgeGenderDist", w: 4, h: 8, x: 0, y: 0 },
    { i: "RaceDist", w: 4, h: 8, x: 6, y: 0 },
  ],
};
