import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

export default function TotalPatientsTestedPositive({ data }) {

  // const convertDate = (time) => {
  //   const date = new Date(time);
  //   const newDate = date.getTime();
  //   return newDate;
  // };

  // const updateData = data.map(({ TotalPatientsTestedPositive, Labsordered, date }) => {
  //   return {
  //     TotalPatientsTestedPositive: TotalPatientsTestedPositive,
  //     Labsordered: Labsordered,
  //     date: convertDate(date),
  //   };
  // });
  const data1 = data.sort((a,b) => a.date - b.date)
  const result1 = data1.map(({ TotalPatientsTestedPositive, date }) => [
    parseFloat(date + "000"),
    parseFloat(TotalPatientsTestedPositive),
  ]);
  const result2 = data1.map(({ Labsordered, date }) => [
    parseFloat(date + "000"),
    parseFloat(Labsordered),
  ]);

  const options = {
    exporting: {
      chartOptions: {
        // specific options for the exported image
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      fallbackToExportServer: false,
    },
    credits: {
      enabled: false,
    },
    chart: {
      
      zoomType: 'x'
    },
 yAxis: {
      tickInterval: 100,
      opposite: false,
    },
    xAxis: {
      minRange: 3600 * 1000 * 24 * 7// one day
    },
    rangeSelector: {
      buttons: [
        {
          type: "week",
          count: 1,
          text: "1w",
        },
        {
          type: "week",
          count: 2,
          text: "2w",
        },
        {
          type: "month",
          count: 1,
          text: "1m",
        },
        {
          type: "all",
          count: 1,
          text: "All",
        },
      ],
   
      selected: 0,
    },

    series: [
      {
        
        name: "Tested Positive",
        color: "rgb(52, 181, 191)",
        data: result1,
        tooltip: {
        
        },
        marker: {
          enabled: true,
          radius: 4
      },
      },
      {
        name: "Total Patients",
        data: result2,
        color: "#5D7092",
        tooltip: {
       
        },
        marker: {
          enabled: true,
          radius: 4
      },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
    </div>
  );
}
