import React from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import config from "./config";
import CorsErrorModal from "./CorsErrorModal";
import { makeStyles } from "@material-ui/core/styles";
import AppRoutes from "./routes/AppRoutes";

const oktaAuth = new OktaAuth(config.oidc);

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  welcomeBlock: {
    width: 500,
    height: 100,
    padding: 50,
  },
  root: {
    display: "flex",
    height: "100%",
    background: "#f5f7fa",
  },
  footerGrid: {
    position: "relative",
  },
}));

const App = () => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
        <AppRoutes />
      </div>
    </Security>
  );
};

export default App;
