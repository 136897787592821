import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes, { object } from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OverviewTable from "./OverviewTable";
import { makeStyles } from "@material-ui/core/styles";
import AdultBedsTable from "./AdultBedsTable";
import PediatricBedsTable from "./PediatricBedsTable";
import SpecialtyBedsTable from "./SpecialtyBedsTable";
import MomAndBabyBedsTable from "./MomAndBabyBedsTable";
import "./LiveBedCapacity.css";
import TotalAvailableBeds from "./TotalAvailableBeds";
import { useMediaQuery } from "react-responsive";
import { TextField } from "@material-ui/core";
import { Multiselect } from "react-widgets";
import { hospitalTypes, coalitionRegions } from "./BedCapacityConstants";

import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "rgb(8,87,97)",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "rgb(21,114,124)",
      color: "white",
      boxShadow: "4px 4px 20px 0px black",
    },
    "& .Mui-disabled": {
      color: "rgb(212, 212, 212)",
    },
  },
});

function a11yProps(index) {
  return {
    id: `full-width-tabpanel-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  sx: {
    "&& .Mui-selected": {
      backgroundColor: "#ebf8fa",
    },
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};
export default function BedCapacityGroup({ data }) {
  const [value, setValue] = React.useState(
    parseInt(sessionStorage.getItem("tabValue")) || 0
  );
  // const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [filterBedsData, setFilterBedsData] = React.useState(
    data.per_hospital_summary
  );
  const [bedsData, setBedsData] = React.useState(data.per_hospital_summary);
  const [filterInput, setFilterInput] = useState("");
  const [hospitalTypeFilterInput, setHospitalTypeFilterInput] = React.useState(
    []
  );
  const [coalitionRegionFilterInput, setCoalitionRegionFilterInput] =
    React.useState([]);
  const [totalOverviewData, setTotalOverviewData] = React.useState([]);
  const [totalAdultData, setTotalAdultData] = React.useState([]);
  const [totalPediatriData, setTotalPediatricData] = React.useState([]);
  const [totalSpecialtyData, setTotalSpecialtyData] = React.useState([]);
  const [totalMomAndBabyData, setTotalMomAndBabyData] = React.useState([]);
  const classes = useStyles();

  const isALlRegionsSelected =
    coalitionRegions.length > 0 &&
    coalitionRegionFilterInput?.length === coalitionRegions.length;

  const isAllHospitalTypesSelected =
    hospitalTypes.length > 0 &&
    hospitalTypeFilterInput?.length === hospitalTypes.length;

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isTabDevice = useMediaQuery({
    query: "(max-device-width: 1150px) ",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    setBedsData(data.per_hospital_summary);
    setFilterBedsData(data.per_hospital_summary);
    setTotalOverviewData(data.total_hospital_summary.TotalOverviewData);
    setTotalAdultData(data.total_hospital_summary.TotalAdultsIcuData);
    setTotalPediatricData(data.total_hospital_summary.TotalPediatricIcuData);
    setTotalSpecialtyData(data.total_hospital_summary.TotalSpecialityData);
    setTotalMomAndBabyData(data.total_hospital_summary.TotalMomAndBabyData);
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("tabValue", newValue);
  };
  const filteredData = filterBedsData.filter((hospital) => {
    // Filter based on hospital name
    if (
      filterInput &&
      !hospital.HOSPITAL_NAME.toLowerCase().includes(filterInput.toLowerCase())
    ) {
      return false;
    }

    // Filter based on hospital type
    if (
      hospitalTypeFilterInput.length &&
      !hospitalTypeFilterInput.includes(hospital.HOSPITAL_TYPE)
    ) {
      return false;
    }

    // Filter based on coalition region
    if (
      coalitionRegionFilterInput.length &&
      !coalitionRegionFilterInput.includes(hospital.COALITION_REGION)
    ) {
      return false;
    }

    return true;
  });

  useEffect(() => {
    setBedsData(filteredData);
  }, [filterInput, hospitalTypeFilterInput, coalitionRegionFilterInput]);

  const renderValue = (selected) => {
    if (selected.length === options.length) {
      return "Deselect All";
    }
    return selected.join(", ");
  };

  const parsedData = bedsData.map((obj) => {
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === null) {
        obj[key] = "-";
      }
    });
  });

  return (
    <div>
      <Box sx={{ margin: "-7px", borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          variant="scrollable"
        >
          {["Overview", "Adult", "Pediatric", "Specialty", "Mom and Baby"].map(
            (label, index) => (
              <Tab
                key={label}
                className="tabs"
                label={label}
                sx={{
                  borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                {...a11yProps(index)}
                disabled={index >= 3}
              />
            )
          )}
        </Tabs>
      </Box>
      <div
        className="occupancyBox1"
        style={{
          paddingLeft: "10px",
          display: "flex",
          flexDirection: isMobileDevice ? "column" : "row",
          marginTop: "8px",
        }}
      >
        <span
          style={{
            fontSize: "14px",
            fontWeight: "bolder",
            marginRight: "10px",
            marginTop: "15px",
          }}
        >
          Filters:
        </span>

        {/* <Multiselect
          className="dropdown_customized"
          data={hospitalTypes}
          value={hospitalTypeFilterInput}
          onChange={handleHospitalTypeChange}
        /> */}

        <FormControl
          className="filter-group"
          style={{ minWidth: 200, margin: 2.5, marginTop: "8px" }}
          size="small"
        >
          <TextField
            inputProps={{
              style: {
                height: "13px",
              },
            }}
            id="outlined-basic-small"
            size="small"
            type="text"
            variant="outlined"
            value={filterInput}
            placeholder="Hospital Name"
            onChange={(event) => setFilterInput(event.target.value)}
          ></TextField>
        </FormControl>

        <FormControl
          className="filter-group"
          size="small"
          variant="outlined"
          style={{ minWidth: 200, margin: 2.5 }}
        >
          <InputLabel id="demo-multiple-chip-label">Hospital Type</InputLabel>

          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-checkbox-county"
            displayEmpty
            variant="outlined"
            multiple={true}
            value={hospitalTypeFilterInput || []}
            MenuProps={MenuProps}
            onChange={(event) =>
              event.target.value.includes("all")
                ? setHospitalTypeFilterInput(
                    event.target.value.length === hospitalTypes.length + 1
                      ? []
                      : hospitalTypes
                  )
                : setHospitalTypeFilterInput(event.target.value)
            }
            label="Hospital Type"
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  marginTop: "5px",
                }}
              >
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    size="small"
                    sx={{
                      fontSize: "12px",
                      backgroundColor: "#075660",
                      color: "white",
                    }}
                  />
                ))}
              </Box>
            )}
          >
            <MenuItem value="all">
              <Checkbox
                checked={isAllHospitalTypesSelected}
                indeterminate={isAllHospitalTypesSelected}
                indeterminateIcon={<CloseOutlined />}
              />
              <ListItemText
                primary={
                  isAllHospitalTypesSelected ? "Unselect All" : "Select All"
                }
              />
            </MenuItem>

            {hospitalTypes.map((c) => {
              return (
                <MenuItem key={c} value={c}>
                  {" "}
                  <Checkbox checked={hospitalTypeFilterInput.indexOf(c) > -1} />
                  <ListItemText primary={c} />{" "}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl
          className="filter-group"
          size="small"
          variant="outlined"
          style={{ minWidth: 200, margin: 2.5 }}
        >
          <InputLabel id="demo-multiple-chip-label">
            Coalition Region
          </InputLabel>

          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-checkbox-county"
            displayEmpty
            variant="outlined"
            multiple={true}
            value={coalitionRegionFilterInput || []}
            MenuProps={MenuProps}
            onChange={(event) =>
              event.target.value.includes("all")
                ? setCoalitionRegionFilterInput(
                    event.target.value.length === coalitionRegions.length + 1
                      ? []
                      : coalitionRegions
                  )
                : setCoalitionRegionFilterInput(event.target.value)
            }
            label="Coalition Region"
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  marginTop: "5px",
                }}
              >
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    size="small"
                    sx={{
                      fontSize: "12px",
                      backgroundColor: "#075660",
                      color: "white",
                    }}
                  />
                ))}
              </Box>
            )}
          >
            <MenuItem value="all">
              <Checkbox
                checked={isALlRegionsSelected}
                indeterminate={isALlRegionsSelected}
                indeterminateIcon={<CloseOutlined />}
              />
              <ListItemText
                primary={isALlRegionsSelected ? "Unselect All" : "Select All"}
              />
            </MenuItem>
            {coalitionRegions.map((c) => {
              return (
                <MenuItem key={c} value={c}>
                  {" "}
                  <Checkbox
                    checked={coalitionRegionFilterInput.indexOf(c) > -1}
                  />
                  <ListItemText primary={c} />{" "}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {/* <Button
          onClick={handleClickOpen}
          style={{ height: "35px", margin: "5px" }}
          variant="outlined"
        >
          {" "}
          + More Filters
        </Button> */}
      </div>

      {/* <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Coalition Region </DialogTitle>
        <DialogContent sx={{ height: "240px" }}>
          {" "}
        
          <Multiselect
            className="dropdown_customized_2"
            data={coalitionRegions}
            value={coalitionRegionFilterInput}
            onChange={handleCoalitionRegionChange}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog> */}
      <Box sx={{ width: "100%" }}>
        <div className="legend-box">
          <div className={isMobileDevice || isTabDevice ? "" : "legend-labels"}>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "bolder",
              }}
            >
              Legend:
            </span>
            <div>
              <span id="circle2"> &nbsp; &nbsp; </span> &nbsp;Greater than or
              equal to 80%
            </div>
            <div>
              <span id="circle3"> &nbsp; &nbsp; </span> &nbsp;between 60% to 79%
            </div>
            <div>
              <span id="circle4"> &nbsp; &nbsp; </span> &nbsp;less than 60%
            </div>
          </div>
        </div>

        <TabPanel value={value} index={0}>
          <TotalAvailableBeds
            data={totalOverviewData}
            beds={bedsData}
            type="All Beds"
          />
          <OverviewTable data={bedsData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TotalAvailableBeds
            data={totalAdultData}
            beds={bedsData}
            type="All Beds Adult"
          />
          <AdultBedsTable data={bedsData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TotalAvailableBeds
            data={totalPediatriData}
            beds={bedsData}
            type="All Beds Pediatric"
          />
          <PediatricBedsTable data={bedsData} />
        </TabPanel>
        <TabPanel disabled value={value} index={3}>
          <TotalAvailableBeds data={totalSpecialtyData} beds={bedsData} />
          <SpecialtyBedsTable data={bedsData} />
        </TabPanel>
        <TabPanel disabled value={value} index={4}>
          <TotalAvailableBeds data={totalMomAndBabyData} beds={bedsData} />
          <MomAndBabyBedsTable data={bedsData} />
        </TabPanel>
      </Box>
    </div>
  );
}
