import React, { Component } from "react";
import { render } from "react-dom";
import { Responsive, WidthProvider } from "react-grid-layout";
import "../../adt/adtCharts/styles.css";
import ChronicConditionsByEthnicity from "./ChronicConditionsByEthnicity";
import ChronicConditionsByRace from "./ChronicConditionsByRace";
import CovidAgeGenderDist from "./CovidAgeGenderDist";
import CovidChronicConditionsDist from "./CovidChronicConditionsDist";
import CovidEthnicityDist from "./CovidEthnicityDist";
import CovidRaceDist from "./CovidRaceDist";
import UtilizationsEdVisits from "./UtilizationsEdVisits";
import UtilizationsIpVisits from "./UtilizationsIpVisits";

const GridLayout = WidthProvider(Responsive);

export default class Covid19ReflowLayout extends Component {
  state = {
    currentBreakpoint: "",
    layouts: storedLayouts,
  };

  static defaultProps = {
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    className: "layout",
    rowHeight: 50,
    isResizable: true,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    // compactType: "horizontal"
  };

  onLayoutChange = (layout, layouts) => {
    this.setState({ layouts });
  };

  onBreakpointChange = (breakpoint) => {
    this.setState({ currentBreakpoint: breakpoint });
  };

  render() {
    return (
      <main>
        <GridLayout
          {...this.props}
          layouts={this.state.layouts}
          onLayoutChange={(layout, layouts) =>
            this.onLayoutChange(layout, layouts)
          }
          onBreakpointChange={this.onBreakpointChange}
        >
          <div key="AgeGenderDist" className="box" ref={this.reference}>
            <CovidAgeGenderDist />
          </div>
          <div key="ChronicConditionsDist" className="box">
            <CovidChronicConditionsDist />
          </div>
          <div key="EthnicityDist" className="box">
            <CovidEthnicityDist />
          </div>
          <div key="RaceDist" className="box">
            <CovidRaceDist />
          </div>
          <div key="ChronicConditionsByEthnicity" className="box">
            <ChronicConditionsByEthnicity />
          </div>
          <div key="ChronicConditionsByRace" className="box">
            <ChronicConditionsByRace />
          </div>
          <div key="IpVisits" className="box">
            <UtilizationsIpVisits />
          </div>
          <div key="EdVisits" className="box">
            <UtilizationsEdVisits />
          </div>
        </GridLayout>
      </main>
    );
  }
}

const storedLayouts = {
  lg: [
    { i: "AgeGenderDist", w: 6, h: 7, x: 0, y: 0, minH: 6, minW: 4 },
    { i: "ChronicConditionsDist", w: 6, h: 7, x: 6, y: 0, minH: 6, minW: 4 },
    { i: "EthnicityDist", w: 6, h: 7, x: 0, y: 0, minH: 6, minW: 3 },
    { i: "RaceDist", w: 6, h: 7, x: 6, y: 0, minH: 6, minW: 3 },
    {
      i: "ChronicConditionsByEthnicity",
      w: 6,
      h: 7,
      x: 0,
      y: 0,
      minH: 6,
      minW: 4,
    },
    { i: "ChronicConditionsByRace", w: 6, h: 7, x: 6, y: 0, minH: 6, minW: 4 },
    { i: "IpVisits", w: 6, h: 7, x: 0, y: 0, minH: 6, minW: 3 },
    { i: "EdVisits", w: 6, h: 7, x: 6, y: 0, minH: 6, minW: 3 },
  ],
  md: [
    { i: "AgeGenderDist", w: 6, h: 7, x: 0, y: 0 },
    { i: "ChronicConditionsDist", w: 6, h: 7, x: 6, y: 0 },
    { i: "EthnicityDist", w: 6, h: 8, x: 0, y: 0 },
    { i: "RaceDist", w: 6, h: 7, x: 6, y: 0 },
    { i: "ChronicConditionsByEthnicity", w: 6, h: 7, x: 0, y: 0 },
    { i: "ChronicConditionsByRace", w: 6, h: 7, x: 6, y: 0 },
    { i: "IpVisits", w: 6, h: 8, x: 0, y: 0 },
    { i: "EdVisits", w: 6, h: 7, x: 6, y: 0 },
  ],
  sm: [
    { i: "AgeGenderDist", w: 6, h: 7, x: 0, y: 0 },
    { i: "ChronicConditionsDist", w: 6, h: 7, x: 6, y: 0 },
    { i: "EthnicityDist", w: 6, h: 7, x: 0, y: 0 },
    { i: "RaceDist", w: 6, h: 7, x: 6, y: 0 },
    { i: "ChronicConditionsByEthnicity", w: 6, h: 7, x: 0, y: 0 },
    { i: "ChronicConditionsByRace", w: 6, h: 7, x: 6, y: 0 },
    { i: "IpVisits", w: 6, h: 7, x: 0, y: 0 },
    { i: "EdVisits", w: 6, h: 7, x: 6, y: 0 },
  ],
  xs: [
    { i: "AgeGenderDist", w: 4, h: 8, x: 0, y: 0 },
    { i: "ChronicConditionsDist", w: 6, h: 8, x: 0, y: 0 },
    { i: "EthnicityDist", w: 4, h: 6, x: 0, y: 0 },
    { i: "RaceDist", w: 4, h: 6, x: 0, y: 0 },
    { i: "ChronicConditionsByEthnicity", w: 4, h: 8, x: 0, y: 0 },
    { i: "ChronicConditionsByRace", w: 6, h: 8, x: 0, y: 0 },
    { i: "IpVisits", w: 4, h: 6, x: 0, y: 0 },
    { i: "EdVisits", w: 4, h: 6, x: 0, y: 0 },
  ],
  xxs: [
    { i: "AgeGenderDist", w: 4, h: 8, x: 0, y: 0 },
    { i: "ChronicConditionsDist", w: 6, h: 8, x: 0, y: 0 },
    { i: "EthnicityDist", w: 4, h: 6, x: 0, y: 0 },
    { i: "RaceDist", w: 4, h: 6, x: 0, y: 0 },
    { i: "ChronicConditionsByEthnicity", w: 4, h: 8, x: 0, y: 0 },
    { i: "ChronicConditionsByRace", w: 6, h: 8, x: 0, y: 0 },
    { i: "IpVisits", w: 4, h: 6, x: 0, y: 0 },
    { i: "EdVisits", w: 4, h: 6, x: 0, y: 0 },
  ],
};
