import { Warning } from "@material-ui/icons";
import { WarningAmberOutlined } from "@mui/icons-material";
import { margin } from "@mui/system";

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 25,
    width: "120px",
    backgroundColor: "smokewhite",
    borderRadius: 10,
    border: "solid",
    borderWidth: "0.5px",
    borderColor: "gray",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed > 100 ? 100 : completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 10,
    textAlign: "center",
  };

  const labelStyles = {
    padding: 5,
    color: "black",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`} </span>{" "}
        {completed > 100 ? (
          <span className="livebadge2 pulsate2">
            <WarningAmberOutlined
              style={{ color: "white", fontSize: "20px" }}
            />
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
