import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Calculate, CalculateSharp } from "@mui/icons-material";

const LabsAgeGenderDist = ({ dataType, data }) => {
  const categoriesSequence = [
    "65 years and above",
"50-65 years",
"40-50 years",
"30-40 years",
"18-30 years",
"12-18 years",
"6-12 years",
"3-6 years",
"1-3 years",
"1 month - 1 year",
"0-1 month"
  ];
  const calFuncFemale = (obj) => {
    // console.log(obj);
    var holder = {};

    obj.forEach(function (d) {
      if (d == undefined) {
        return null;
      } else if (holder.hasOwnProperty(d.Age)) {
        holder[d.Age] = holder[d.Age] + parseInt(d.AverageFemale);
      } else {
        holder[d.Age] = parseInt(d.AverageFemale);
      }
    });

    var obj2 = [];

    for (let prop in holder) {
      obj2.push({
        Age: prop,
        AverageFemale: holder[prop],
      });
    }

    return obj2;
  };

  const calFuncMale = (obj) => {
    // console.log(obj);
    var holder = {};

    obj.forEach(function (d) {
      if (d == undefined) {
        return null;
      } else if (holder.hasOwnProperty(d.Age)) {
        holder[d.Age] = holder[d.Age] + parseInt(d.AverageMale);
      } else {
        holder[d.Age] = parseInt(d.AverageMale);
      }
    });

    var obj2 = [];

    for (let prop in holder) {
      obj2.push({
        Age: prop,
        AverageMale: holder[prop],
      });
    }

    return obj2;
  };
  const canlculatePercentFemale = (arr) => {
    const sum =
      arr && arr.reduce((partialSum, a) => partialSum + a.AverageFemale, 0);
    const res =
      arr &&
      arr.map((obj) => {
        return {
          Age: obj.Age,
          AverageFemale: obj.AverageFemale,
          PercentageFemale: (obj.AverageFemale / sum) * 100,
        };
      });
    const finalData = res && res.sort(
      (a, b) =>
        categoriesSequence.indexOf(a.Age) - categoriesSequence.indexOf(b.Age)
    );
    return finalData;
  };
  const canlculatePercentMale = (arr) => {
    const sum =
      arr && arr.reduce((partialSum, a) => partialSum + a.AverageMale, 0);
    const res =
      arr &&
      arr.map((obj) => {
        return {
          Age: obj.Age,
          AverageMale: obj.AverageMale,
          PercentageMale: parseFloat((obj.AverageMale / sum) * -100),
        };
      });
    const finalData = res && res.sort(
      (a, b) =>
        categoriesSequence.indexOf(a.Age) - categoriesSequence.indexOf(b.Age)
    );
    return finalData;
  };

  const selectAndPrepareFemale = (data) => {
    const rsvMapData = data && data.map((a) => {
      return a.AgeAndGenderRsv;
    });
    const covidMapData = data && data.map((a) => {
      return a.AgeAndGenderCovid;
    });
    const influenzaMapData = data && data.map((a) => {
      return a.AgeAndGenderInfluenza;
    });
    const finalRsv = calFuncFemale(rsvMapData.flat());
    const finalCovid = calFuncFemale(covidMapData.flat());
    const finalInfluenza = calFuncFemale(influenzaMapData.flat());

    const finalCovAndInflu = calFuncFemale(finalCovid.concat(finalInfluenza));

    const finalInfluAndRsv = calFuncFemale(finalRsv.concat(finalInfluenza));
    const finalCovidAndRsv = calFuncFemale(finalRsv.concat(finalCovid));
    const finalCovidRsvInflu = calFuncFemale(
      finalCovidAndRsv.concat(finalInfluenza)
    );

    // console.log(finalInfluAndRsv);
    // console.log(final)
    // setMapTypeData(final)
    // const covidMapData = data.map((a) => {
    //   return a.NebraskaCasesCovid;
    // });
    // const influenzaMapData = data.map((a) => {
    //   return a.NebraskaCasesInfluenza;
    // });

    if (
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      return finalRsv;
    } else if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      return finalInfluenza;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Rsv") < 0
    ) {
      return finalCovid;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1
    ) {
      return finalCovidRsvInflu;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0
    ) {
      return finalCovAndInflu;
    } else if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Covid") < 0
    ) {
      return finalInfluAndRsv;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0
    ) {
      return finalCovidAndRsv;
    }
  };

  const selectAndPrepareMale = (data) => {
    const rsvMapData = data && data.map((a) => {
      return a.AgeAndGenderRsv;
    });
    const covidMapData = data && data.map((a) => {
      return a.AgeAndGenderCovid;
    });
    const influenzaMapData = data && data.map((a) => {
      return a.AgeAndGenderInfluenza;
    });
    const finalMaleRsv = calFuncMale(rsvMapData.flat());
    const finalMaleCovid = calFuncMale(covidMapData.flat());
    const finalMaleInfluenza = calFuncMale(influenzaMapData.flat());

    const finalMaleCovAndInflu = calFuncMale(
      finalMaleCovid.concat(finalMaleInfluenza)
    );

    const finalMaleInfluAndRsv = calFuncMale(
      finalMaleRsv.concat(finalMaleInfluenza)
    );
    const finalMaleCovidAndRsv = calFuncMale(
      finalMaleRsv.concat(finalMaleCovid)
    );
    const finalMaleCovidRsvInflu = calFuncMale(
      finalMaleCovidAndRsv.concat(finalMaleInfluenza)
    );

    if (
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      return finalMaleRsv;
    } else if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      return finalMaleInfluenza;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Rsv") < 0
    ) {
      return finalMaleCovid;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1
    ) {
      return finalMaleCovidRsvInflu;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0
    ) {
      return finalMaleCovAndInflu;
    } else if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Covid") < 0
    ) {
      return finalMaleInfluAndRsv;
    } else if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0
    ) {
      return finalMaleCovidAndRsv;
    }
  };

  // console.log(selectAndPrepare(data));
  const finalDataFemale = canlculatePercentFemale(selectAndPrepareFemale(data));
  const finalDataMale = canlculatePercentMale(selectAndPrepareMale(data));
  // console.log(finalDataFemale);
  useEffect(() => {
    selectAndPrepareFemale(data)
    selectAndPrepareMale(data)
  }, [data, dataType]);

  const FemaleData =
    finalDataFemale && finalDataFemale.map((n) => n.PercentageFemale);
  const MaleData = finalDataMale && finalDataMale.map((n) => n.PercentageMale);
  const categories = finalDataFemale && finalDataFemale.map((n) => n.Age);
  // console.log(categories);
  // console.log(FemaleData);
  const options = {
    chart: {
      type: "bar"
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    title: {
      text: "Age and Gender Distribution of Positive Tests",
      style: {
        fontSize: "14px",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: [
      {
        categories: categories,
        reversed: false,
        accessibility: {
          description: "Percentage",
        },
        title: {
          text: null,
        },
        labels: {
          step: 1,
        },
      },
      {
        // mirror axis on right side
        opposite: true,
        reversed: false,
        categories: categories,
        linkedTo: 0,
        labels: {
          step: 1,
        },
      },
    ],
    yAxis: {
      title: {
        text: null,
      },

      labels: {
        formatter: function () {
          return Math.abs(this.value) + "%";
        },
        // overflow: "justify",
      },
      accessibility: {
        description: "Average population",
      },
    },

    plotOptions: {
      series: {
        stacking: "normal",
      },
      bar: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(Math.abs(this.point.y), 0) + "%";
          },
        },
      },
    },

    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.series.name +
          ", Age Group: " +
          this.point.category +
          "</b><br/>" +
          "Percentage: " +
          Highcharts.numberFormat(Math.abs(this.point.y), 3) +
          "%"
        );
      },
    },

    series: [
    
      {
        name: "Male",
        data: MaleData || "",
        color: "rgb(52, 181, 191)",
      },
      {
        name: "Female",
        data: FemaleData || "",
        color: "#5D7092",
      },


      // {
      //   name: "Other",
      //   data: averageOther,
      //   color: "rgb(25, 100, 191)",
      // },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default LabsAgeGenderDist;
