import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ScienceIcon from "@mui/icons-material/Science";
import DoneIcon from "@mui/icons-material/Done";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TimelineIcon from "@mui/icons-material/Timeline";
import GroupIcon from "@mui/icons-material/Group";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "black",
  },
  text: {
    marginLeft: "-20px",
    color: "rgb(8,87,97)",
    fontStyle: "bold",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
    fontSize: "25px",
  },
  iconBox: {
    color: "rgb(13,202,240)",
    fontSize: "large",
    border: " 0px solid rgb(13,202,240)",
    borderRadius: "100%",
    backgroundColor: "gray",
    margin: "3px",
    padding: "5px",
  },
});

function TotalCovidData({ data }) {
  const classes = useStyles();
  // console.log(data);

  return (
    <div>
      {/* Total beds card */}
      <h6 className="card-header bg-light">
        <ListItem className={classes.root}>
          <ListItemIcon>
            <GroupIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.text}>
            COVID-19 PATIENTS
          </ListItemText>
        </ListItem>{" "}
      </h6>{" "}
      <div className="row pt-4 pb-4">
        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.OverallPositive}
              </h2>
              <p className="card-text text-muted">Overall Positve</p>
            </div>
          </div>
        </div>

        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.PositiveLab}
              </h2>
              <p className="card-text text-muted">Positive Lab</p>
            </div>
          </div>
        </div>

        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.CovidDiagnosisNoLab}
              </h2>
              <p className="card-text text-muted">Covid Diagnosis No Lab</p>
            </div>
          </div>
        </div>

        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.ActiveHospitalized}
              </h2>
              <p className="card-text text-muted">Active Hospitalized</p>
            </div>
          </div>
        </div>

        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.Recovered}
              </h2>
              <p className="card-text text-muted">Recovered</p>
            </div>
          </div>
        </div>

        <div className="col-sm-2">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.Deceased}
              </h2>
              <p className="card-text text-muted">Deceased</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalCovidData;
