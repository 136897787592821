import React, { Component } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import AgeGenderDist from "./AgeGenderDist";
import EthnicityDist from "./EthnicityDist";
import OrderedVsCompleted from "./OrderedVsCompleted";
import RaceDist from "./RaceDist";
import TopSixCounties from "./TopSixCounties";
import TotalPatientsTestedPositive from "./TotalPatientsTestedPositive";
import "./styles.css";
import SampleMap from "./SampleMap";
import { IconButton } from "@mui/material";
import { DragHandle, DragIndicator } from "@material-ui/icons";

const GridLayout = WidthProvider(Responsive);

export default class MyFirstGrid extends Component {
  state = {
    currentBreakpoint: "",
    layouts: storedLayouts,
  };

  static defaultProps = {
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    className: "layout",
    rowHeight: 50,
    isResizable: false,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    // compactType: "horizontal"
  };
  stopPropagation = (event) => {
    event.stopPropagation();
  };
  onLayoutChange = (layout, layouts) => {
    this.setState({ layouts });
  };

  onBreakpointChange = (breakpoint) => {
    this.setState({ currentBreakpoint: breakpoint });
  };

  render(props) {
    const ageGenderDistData = {};
    this.props.data.ageGenderDist.forEach(obj => {
      if (!ageGenderDistData[obj.Age]) {
        ageGenderDistData[obj.Age] = {
          ...obj,
          AverageMale: parseFloat(obj.AverageMale),
          AverageFemale: parseFloat(obj.AverageFemale)
        };
      }
      else {
        ageGenderDistData[obj.Age]['AverageMale'] += parseFloat(obj['AverageMale']);
        ageGenderDistData[obj.Age]['AverageFemale'] += parseFloat(obj['AverageFemale']);
      }
    })

    const nebraskaCasesByCounty = {};
    this.props.data.nebraskaCases.forEach(obj => {
      if (!nebraskaCasesByCounty[obj.County]) {
        nebraskaCasesByCounty[obj.County] = {
          ...obj,
          PatientsTestedPositivePerCounty: parseInt(obj.PatientsTestedPositivePerCounty)
        };
      }
      else {
        nebraskaCasesByCounty[obj.County]['PatientsTestedPositivePerCounty'] += parseInt(obj['PatientsTestedPositivePerCounty']);
      }
    })
    const nebraskaCasesByCountySorted = Object.values(nebraskaCasesByCounty)
      .sort((o1, o2) => o2.PatientsTestedPositivePerCounty - o1.PatientsTestedPositivePerCounty);

    const raceDist = {
      "AmericanIndianAlaskaNative": 0,
      "Asian": 0,
      "BlackAfricanAmerican": 0,
      "NativeHawaiianOrOtherPacificIslander": 0,
      "other": 0,
      "white": 0,
      "HispanicLatino": 0,
      "NonHispanicLatino": 0,
    };
    this.props.data.raceDist.forEach(obj => {
      Object.keys(raceDist).forEach(key => {
        raceDist[key] += parseFloat(obj[key]);
      })
    })

    return (
      <main>
        <GridLayout
          {...this.props}
          draggableHandle=".drag-handle"
          layouts={this.state.layouts}
          onLayoutChange={(layout, layouts) =>
            this.onLayoutChange(layout, layouts)
          }
          onBreakpointChange={this.onBreakpointChange}
        >
          <div
            key="OrderedVsCompleted"
            className="box"
            style={{ backgroundColor: "white" }}
          >
            Lab Ordered vs. Completed
            <OrderedVsCompleted data={this.props.data.orderedVsCompleted} />
          </div>

          <div
            key="TotalPatientsTestedPositive"
            className="box"
            style={{ backgroundColor: "white" }}
          >
            Total Patients vs. Tested Positive
            <TotalPatientsTestedPositive
              data={this.props.data.totalPatsPositive}
            />
          </div>

          <div key="MapComponent" className="box">
            Nebraska Counties{" "}
            <IconButton style={{ cursor: "grab" }} className="drag-handle">
              {" "}
              <DragIndicator />
            </IconButton>
            <SampleMap data={nebraskaCasesByCountySorted} />
          </div>

          <div key="TopSixCounties" className="box">
            Top 6 Counties{" "}
            <IconButton style={{ cursor: "grab" }} className="drag-handle">
              <DragIndicator />
            </IconButton>
            <TopSixCounties data={nebraskaCasesByCountySorted.slice(0,6)} />
          </div>

          <div key="AgeGenderDist" className="box">
            Age and Gender Distribution of Positive Tests{" "}
            <IconButton style={{ cursor: "grab" }} className="drag-handle">
              <DragIndicator />
            </IconButton>
            <AgeGenderDist data={Object.values(ageGenderDistData)} selected={this.props.selectedFilter} />
          </div>

          <div key="RaceDist" className="box">
            Race Distribution of Positive Tests{" "}
            <IconButton style={{ cursor: "grab" }} className="drag-handle">
              <DragIndicator />
            </IconButton>
            <RaceDist data={raceDist} />
          </div>
          <div key="LabVsPositive" className="box">
            Ethnicity Distribution of Positive Tests{" "}
            <IconButton style={{ cursor: "grab" }} className="drag-handle">
              <DragIndicator />
            </IconButton>
            <EthnicityDist data={raceDist} />
          </div>
        </GridLayout>
      </main>
    );
  }
}

const storedLayouts = {
  lg: [
    {
      i: "OrderedVsCompleted",
      w: 6,
      h: 8,
      x: 0,
      y: 0,
      minH: 4,
      minW: 4,
      static: true,
    },
    {
      i: "TotalPatientsTestedPositive",
      w: 6,
      h: 8,
      x: 6,
      y: 0,
      minH: 7.5,
      minW: 4,
      static: true,
    },
    { i: "MapComponent", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "TopSixCounties", w: 6, h: 6, x: 6, y: 0, minH: 6, minW: 3 },
    { i: "LabVsPositive", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "AgeGenderDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
    { i: "RaceDist", w: 6, h: 8, x: 0, y: 0, minH: 8, minW: 3 },
  ],
  md: [
    {
      i: "OrderedVsCompleted",
      w: 6,
      h: 8,
      x: 0,
      y: 0,
      minH: 4,
      minW: 4,
      static: true,
    },
    {
      i: "TotalPatientsTestedPositive",
      w: 6,
      h: 8,
      x: 6,
      y: 0,
      minH: 7.5,
      minW: 4,
      static: true,
    },
    { i: "MapComponent", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "TopSixCounties", w: 6, h: 6, x: 6, y: 0, minH: 6, minW: 3 },
    { i: "LabVsPositive", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "AgeGenderDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
    { i: "RaceDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
  ],
  sm: [
    {
      i: "OrderedVsCompleted",
      w: 6,
      h: 8,
      x: 0,
      y: 0,
      minH: 4,
      minW: 4,
      static: true,
    },
    {
      i: "TotalPatientsTestedPositive",
      w: 6,
      h: 8,
      x: 6,
      y: 0,
      minH: 7.5,
      minW: 4,
      static: true,
    },
    { i: "MapComponent", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "TopSixCounties", w: 6, h: 6, x: 6, y: 0, minH: 6, minW: 3 },
    { i: "LabVsPositive", w: 6, h: 8, x: 0, y: 0, minH: 7, minW: 3 },
    { i: "AgeGenderDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
    { i: "RaceDist", w: 6, h: 8, x: 6, y: 0, minH: 8, minW: 3 },
  ],
  xs: [
    { i: "OrderedVsCompleted", w: 4, h: 8, x: 0, y: 0 },
    { i: "TotalPatientsTestedPositive", w: 6, h: 8, x: 0, y: 0 },
    { i: "MapComponent", w: 4, h: 6, x: 0, y: 0 },
    { i: "TopSixCounties", w: 4, h: 6, x: 0, y: 0 },
    { i: "LabVsPositive", w: 4, h: 8, x: 0, y: 0 },
    { i: "AgeGenderDist", w: 4, h: 8, x: 0, y: 0 },
    { i: "RaceDist", w: 4, h: 8, x: 0, y: 0 },
  ],
  xxs: [
    { i: "OrderedVsCompleted", w: 4, h: 8, x: 0, y: 0 },
    { i: "TotalPatientsTestedPositive", w: 6, h: 8, x: 0, y: 0 },
    { i: "MapComponent", w: 4, h: 6, x: 0, y: 0 },
    { i: "TopSixCounties", w: 4, h: 6, x: 0, y: 0 },
    { i: "LabVsPositive", w: 4, h: 9, x: 0, y: 0 },
    { i: "AgeGenderDist", w: 4, h: 8, x: 0, y: 0 },
    { i: "RaceDist", w: 4, h: 8, x: 6, y: 0 },
  ],
};
