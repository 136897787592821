import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Person2Icon from "@mui/icons-material/Person2";
import { useEffect } from "react";
const useStyles = makeStyles({
  root: {
    padding: "15px",
    color: "black",
    margin: "5px",
    borderRadius: "10px",
    backgroundColor: "#DCEEF0",
  },
  text: {
    marginLeft: "-20px",
    color: "rgb(8,87,97)",
    fontStyle: "bold",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
    fontSize: "15px",
  },
  iconBox: {
    color: "#b5eaf5",
    fontSize: "small",
    border: " 0px solid rgb(13,202,240)",
    borderRadius: "100%",
    backgroundColor: "rgb(8,87,97)",
    margin: "3px",
    padding: "5px",
  },
});

function TotalUniquePatsSummary({ data, dataType, tab }) {
  const classes = useStyles();
  const [labsOrderedTotal, setLabsOrderedTotal] = React.useState("");
  const [labsCompletedTotal, setLabsCompletedTotal] = React.useState("");
  const [testedPositiveTotal, setTestedPositiveTotal] = React.useState("");
  const percentageValue = Math.floor(
    (testedPositiveTotal / labsCompletedTotal) * 100
  );

  const calculate = () => {
    const calculateSumOfLabsOrdered = data.reduce((accumulator, object) => {
      let initNumber = parseInt(accumulator);
      dataType.forEach(
        (type) =>
          (initNumber += parseInt(
            object[`UniquePatientsLabsOrdered${type}`]
              ? object[`UniquePatientsLabsOrdered${type}`]
              : 0
          ))
      );
      return initNumber;
    }, 0);
    setLabsOrderedTotal(calculateSumOfLabsOrdered);

    const calculateSumOfLabsCompleted = data.reduce((accumulator, object) => {
      let initNumber = parseInt(accumulator);
      dataType.forEach(
        (type) =>
          (initNumber += parseInt(
            object[`UniquePatientsLabsCompleted${type}`]
              ? object[`UniquePatientsLabsCompleted${type}`]
              : 0
          ))
      );
      return initNumber;
    }, 0);
    setLabsCompletedTotal(calculateSumOfLabsCompleted);

    const calculateSumOfTestedPositive = data.reduce((accumulator, object) => {
      let initNumber = parseInt(accumulator);
      dataType.forEach(
        (type) =>
          (initNumber += parseInt(
            object[`UniquePatientsTestedPositive${type}`]
              ? object[`UniquePatientsTestedPositive${type}`]
              : 0
          ))
      );
      return initNumber;
    }, 0);
    setTestedPositiveTotal(calculateSumOfTestedPositive);
  };

  useEffect(() => {
    calculate();
  }, [data, dataType]);

  // console.log(labsOrderedTotal, testedPositiveTotal);
  return (
    <>
      {tab === "overview" ? (
        <div style={{ width: "50%", marginLeft: "20px", marginRight: "10px" }}>
          <h6>
            <ListItem className={classes.root}>
              <ListItemIcon>
                <Person2Icon className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.text}>
                PATIENTS
              </ListItemText>
            </ListItem>{" "}
          </h6>
          <div className="row pt-1 pb-1">
            <div className="col-sm">
              <div className="card-labs">
                <div className="card-body">
                  {/* <PlaylistAddIcon fontSize="large" className={classes.iconBox} /> */}
                  <h4 className="card-title font-weight-bold">
                    {" "}
                    {labsOrderedTotal}
                  </h4>
                  <p className="card-text text-muted">Total Labs Ordered</p>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="card-labs">
                <div className="card-body">
                  {/* <DoneIcon fontSize="large" className={classes.iconBox} /> */}
                  <h4 className="card-title font-weight-bold">
                    {" "}
                    {labsCompletedTotal}
                  </h4>
                  <p className="card-text text-muted">Total Labs Completed</p>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="card-labs">
                <div className="card-body">
                  {/* <PriorityHighIcon fontSize="large" className={classes.iconBox} /> */}
                  <h4 className="card-title font-weight-bold">
                    {" "}
                    {testedPositiveTotal}
                  </h4>
                  <p className="card-text text-muted">Total Tested Positive</p>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="card-labs">
                <div className="card-body">
                  {/* <TimelineIcon fontSize="large" className={classes.iconBox} /> */}
                  <h4 className="card-title font-weight-bold">
                    {" "}
                    {percentageValue}%
                  </h4>
                  <p className="card-text text-muted">Percentage Positive</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <div className="row pt-1 pb-1">
            <div className="col-sm">
              <div className="card-labs">
                <div className="card-body">
                  {/* <PlaylistAddIcon fontSize="large" className={classes.iconBox} /> */}
                  <h4 className="card-title font-weight-bold">
                    {" "}
                    {labsOrderedTotal}
                  </h4>
                  <p className="card-text text-muted">Total Labs Ordered</p>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="card-labs">
                <div className="card-body">
                  {/* <DoneIcon fontSize="large" className={classes.iconBox} /> */}
                  <h4 className="card-title font-weight-bold">
                    {" "}
                    {labsCompletedTotal}
                  </h4>
                  <p className="card-text text-muted">Total Labs Completed</p>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="card-labs">
                <div className="card-body">
                  {/* <PriorityHighIcon fontSize="large" className={classes.iconBox} /> */}
                  <h4 className="card-title font-weight-bold">
                    {" "}
                    {testedPositiveTotal}
                  </h4>
                  <p className="card-text text-muted">Total Tested Positive</p>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="card-labs">
                <div className="card-body">
                  {/* <TimelineIcon fontSize="large" className={classes.iconBox} /> */}
                  <h4 className="card-title font-weight-bold">
                    {" "}
                    {percentageValue}%
                  </h4>
                  <p className="card-text text-muted">Percentage Positive</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TotalUniquePatsSummary;
