import { Warning } from "@material-ui/icons";
import { WarningAmberOutlined } from "@mui/icons-material";
import { margin } from "@mui/system";
const ProgressBarTotals = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 38,
    width: "100%",
    backgroundColor: "smokewhite",
    borderRadius: 10,
    border: "solid",
    borderWidth: "1px",
    borderColor: "#003b42",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed > 100 ? 100 : completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 10,
    textAlign: "center",
  };

  const labelStyles = {
    padding: 0,
    color: "#003b42",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`} </span>{" "}
        {completed > 100 ? (
          <span className="livebadge2 pulsate2">
            <WarningAmberOutlined
              style={{ color: "white", fontSize: "20px" }}
            />
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProgressBarTotals;
