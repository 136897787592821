import React, { Component } from "react";
import HighchartsMap from "highcharts/modules/map";
import Highcharts from "highcharts";
import mapData from "../../../../data/Map";
import exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts Maps as a module
exporting(Highcharts);
HighchartsMap(Highcharts);

export default function SampleMap({ data }) {
  const mapData2 = data.map(
    ({ County: name, PatientsTestedPositivePerCounty: value, ...rest }) => ({
      name,
      value,
      ...rest,
    })
  );
  const mapOptions = {
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    legend: {
      title: {
        text: "Positive Cases",
      },

      align: "left",
      borderWidth: 1,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    colorAxis: {
      min: 0,
      max: 200,

      minColor: "#c5d9fc",
      maxColor: "#414f68",
    },
    mapNavigation: {
      enabled: true,
      enableButtons: false,
    },
    mapView: {
      padding: "2%",
    },
    tooltip: {
      pointFormatter: function () {
        return `<div>
            County: <b>${this.properties["name"]}</b><br>
            Positive cases: <b>${Math.floor(this.value)} Cases </b><br>
    
          </div>`;
      },
      backgroundColor: "white",

      borderColor: "white",
    },

    series: [
      {
        data: mapData2,
        mapData: mapData,
        joinBy: "name",

        dataLabels: {
          enabled: true,
          color: "black",
          format: "{point.name}",
        },

        states: {
          hover: {
            color: "#5D7092",
          },
        },
        cursor: "pointer",
        name: "Nebraska County Data",
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 1000,
          },
        },
      ],
    },
  };
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"mapChart"}
        options={mapOptions}
      />
    </div>
  );
}
