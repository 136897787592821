/* eslint-disable */
import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import "../layout/Navbar.css";
import { Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import HotelIcon from "@material-ui/icons/Hotel";
import ScienceIcon from "@mui/icons-material/Science";
import WelcomeMessage from "../pages/welcome/Welcome";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import GroupIcon from "@material-ui/icons/Group";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginInlineEnd: 7,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  introPaper: {},
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    whiteSpace: "nowrap",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "left",
    justifyContent: "flex-end",
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: "120px",
    textAlign: "center",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.action.active,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Navbar = ({ setCorsErrorModalOpen }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const theme = useTheme();
  const [userInfo, setUserInfo] = useState("");
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";
  const login = async () =>
    oktaAuth.signInWithRedirect({ originalUri: "/menu" });

  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        {authState.isAuthenticated && (
          <>
            <AppBar
              style={{
                textDecoration: "none",
                background:
                  "linear-gradient(-45deg, rgb(52, 181, 191) 30%, rgb(6, 82, 92) 100%)",
              }}
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>

                <img
                  src={process.env.PUBLIC_URL + "/CHicon.png"}
                  width="35"
                  height="35"
                  className="d-inline-block align-top"
                  alt=""
                ></img>
                <Typography
                  className="navbar-brand p-2"
                  style={{ fontSize: "18px" }}
                >
                  CyncHealth
                </Typography>
                <span className="example-fill-remaining-space"></span>
                <Typography
                  id="myHeading"
                  variant="h6"
                  fontWeight="fontWeightBold"
                >
                  NEBRASKA DASHBOARD
                </Typography>

                <span className="example-fill-remaining-space"></span>

                {handleGroupAuth("Admins Nebraska Covid Dashboard") ? (
                  <Link to="/admin">
                    <Tooltip
                      disableFocusListener
                      title="Admin Panel"
                      placement="top-end"
                      arrow
                    >
                      <IconButton color="primary">
                        <AdminPanelSettingsIcon style={{ color: "white" }} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                ) : (
                  ""
                )}

                <Link to="/menu">
                  <IconButton color="primary">
                    <DashboardIcon style={{ color: "white" }} />
                  </IconButton>
                </Link>

                <IconButton color="primary" onClick={handleClick}>
                  <PersonIcon style={{ color: "white" }}></PersonIcon>
                  <KeyboardArrowDownIcon
                    style={{ fontSize: "small", color: "white" }}
                  />
                </IconButton>

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Link
                    to="/profile"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <StyledMenuItem>
                      <ListItemText> Profile </ListItemText>
                    </StyledMenuItem>
                  </Link>

                  {/* <Link
                    to="/contactus"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <StyledMenuItem>
                      <ListItemText> Support </ListItemText>
                    </StyledMenuItem>
                  </Link> */}

                  {handleGroupAuth("Admins Nebraska Covid Dashboard") ? (
                    <Link
                      to="/admin"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <StyledMenuItem>
                        <ListItemText> Admin Panel </ListItemText>
                      </StyledMenuItem>
                    </Link>
                  ) : (
                    " "
                  )}
                </StyledMenu>

                <Button
                  variant="contained"
                  id="logout-button"
                  onClick={logout}
                  endIcon={<PowerSettingsNewIcon />}
                > Logout</Button>
              </Toolbar>
            </AppBar>
          </>
        )}

        {authState.isAuthenticated && (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List
              onMouseOver={handleDrawerOpen}
              onMouseLeave={handleDrawerClose}
            >
              <Link
                to="/menu"
                style={{ textDecoration: "none", color: "grey" }}
              >
                <ListItem button>
                  <Tooltip
                    disableFocusListener
                    title="Menu"
                    placement="top-end"
                    arrow
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText> Menu</ListItemText>
                </ListItem>
              </Link>

              {handleGroupAuth("Nebraska Bed Capacity Dashboard Users") ? (
                <Link
                  to="/bedcapacity"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="Live Bed Capacity"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <HotelIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText>
                      {" "}
                      Live Bed Capacity{" "}
                      <span className="livebadge pulsate ">NRT</span>
                    </ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              {handleGroupAuth("Nebraska Labs Dashboard Users") ? (
                <Link
                  to="/labs"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="Labs Dashboard"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <ScienceIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText>Labs Dashboard{" "}
                      <span className="livebadge pulsate ">NRT</span></ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              {handleGroupAuth("Nebraska ADT Dashboard Users") ? (
                <Link
                  to="/adtdashboard"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="ADT Dashboard"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <BlurOnIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> ADT Dashboard</ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              {handleGroupAuth("Nebraska Covid19 Patients Dashboard Users") ? (
                <Link
                  to="/covid19patients"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="Covid-19 Patients"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> Covid-19 Patients</ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              {handleGroupAuth(
                "Nebraska Capacity Forecasting Dashboard Users"
              ) ? (
                <Link
                  to="/capacityforecasting"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title=" Capacity Forecasting"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <TrendingUpIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> Capacity Forecasting</ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              {handleGroupAuth("Nebraska PPE Dashboard Users") ? (
                <Link
                  to="/ppedashboard"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="PPE Dashboard"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <AddLocationIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> PPE Dashboard</ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              {handleGroupAuth("Nebraska Reports Dashboard Users") ? (
                <Link
                  to="/reports"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="Reports"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <AssessmentIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> Reports</ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              {handleGroupAuth("Nebraska Ad-hoc Reports Dashboard Users") ? (
                <Link
                  to="/adhocreports"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="Ad-Hoc Reports"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <EqualizerIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> Ad-Hoc Reports</ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}
              <Divider />
            </List>
          </Drawer>
        )}

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div>
            {!authState.isPending && !authState.isAuthenticated && (
              <WelcomeMessage />
            )}
          </div>
  
          {/* <div className="footer">
          <Grid container spacing={1} className="mb-3">
            <Grid item xs={5} className="text-center">
             © 2021 All Rights Reserved.
            </Grid>
            <Grid item xs={3} className="text-center">
            Powered by: <a href="https://cynchealth.org/" target="_blank" style={{ textDecoration: 'none', color:'#3F51B5'}} > CyncHealth</a>
            </Grid>
            <Grid item xs={3} className="text-center">
            <a href="https://cynchealth.org/website-privacy/" target="_blank" style={{ textDecoration: 'none', color:'#3F51B5'}}> Privacy Policy</a>
            </Grid>
          </Grid>
           </div> */}
        </main>
      </div>
    </>
  );
};
export default Navbar;
