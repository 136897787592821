import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect } from "react";

const LabsEthnicityDist = ({ data, dataType }) => {
  const [hispanicLatino, setHispanicLatino] = React.useState("");
  const [nonHispanicLatino, setNonHispanicLatino] = React.useState("");
  const [incomplete, setIncomplete] = React.useState("");
  const [notReported, setNotReported] = React.useState("");

  const calculate = () => {
    let calculateSumOfHispanicLatino = 0;
    let calculateSumOfNonHispanicLatino = 0;
    let calculateSumOfIncomplete = 0;
    let calculateSumOfNotReported = 0;

    data.forEach((object) => {
      dataType.forEach((type) => {
        calculateSumOfHispanicLatino += parseFloat(
          object[`HispanicLatino${type}`] || 0
        );
        calculateSumOfNonHispanicLatino += parseFloat(
          object[`NonHispanicLatino${type}`] || 0
        );
        calculateSumOfIncomplete += parseFloat(
          object[`Incomplete${type}`] || 0
        );
        calculateSumOfNotReported += parseFloat(
          object[`NotReported${type}`] || 0
        );
      });
    });

    setHispanicLatino(calculateSumOfHispanicLatino);
    setNonHispanicLatino(calculateSumOfNonHispanicLatino);
    setIncomplete(calculateSumOfIncomplete);
    setNotReported(calculateSumOfNotReported);
  };
  const totalPopulation =
    hispanicLatino + nonHispanicLatino + incomplete + notReported;

  useEffect(() => {
    calculate();
  }, [data, dataType]);

  const newData = [
    [
      "Hispanic Latino",
      parseFloat(((hispanicLatino / totalPopulation) * 100).toFixed(2)),
    ],
    [
      "Non-Hispanic Latino",
      parseFloat(((nonHispanicLatino / totalPopulation) * 100).toFixed(2)),
    ],
    [
      "Incomplete",
      parseFloat(((incomplete / totalPopulation) * 100).toFixed(2)),
    ],
    [
      "Not Reported",
      parseFloat(((notReported / totalPopulation) * 100).toFixed(2)),
    ],
  ];

  const options = {
    chart: {
      type: "column",
      zoomType: "xy",
    },
    title: {
      text: "Ethnicity Distribution of Positive Tests",
      style: {
        fontSize: "14px",
      },
    },

    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    xAxis: {
      type: "category",
      labels: {
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Percentage",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: "%",
    },
    series: [
      {
        name: "Percentage",
        data: newData || "",
        color: "rgb(52, 181, 191)",
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
        immutable={true}
      />
    </div>
  );
};
export default LabsEthnicityDist;
