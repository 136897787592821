import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect } from "react";

const LabsRaceDist = ({ data, dataType }) => {
  console.log(data)
  const [americanIndianAlaskaNative, setAmericanIndianAlaskaNative] = React.useState("");
  const [asian, setAsian] = React.useState("");
  const [blackAfricanAmerican, setBlackAfricanAmerican] = React.useState("");
  const [nativeHawaiianOrOtherPacificIslander, setNativeHawaiianOrOtherPacificIslander] = React.useState("");  
  const [other, setOther] = React.useState("");
  const [white, setWhite] = React.useState(""); 
  const [unknowns, setUnknowns] = React.useState(""); 

  // console.log(data, 'race')

  const calculate = () => {
    const calculateSum = (prop, setter) => {
      const sum = data.reduce(
        (accumulator, object) => {
          let initNumber = parseFloat(accumulator);
          dataType.forEach((type) => initNumber += parseFloat(object[`${prop}${type}`] ? object[`${prop}${type}`] : 0));
          return initNumber;
        },
        0
      );
      setter(sum);
    };
  
    calculateSum("AmericanIndianAlaskaNative", setAmericanIndianAlaskaNative);
    calculateSum("Asian", setAsian);
    calculateSum("BlackAfricanAmerican", setBlackAfricanAmerican);
    calculateSum("NativeHawaiianOrOtherPacificIslander", setNativeHawaiianOrOtherPacificIslander);
    calculateSum("other", setOther);
    calculateSum("white", setWhite);
    calculateSum("Unknowns", setUnknowns);
  };
// console.log(parseFloat(americanIndianAlaskaNative), asian, blackAfricanAmerican, nativeHawaiianOrOtherPacificIslander, other, white, unknowns)
  
const totalPopulation = americanIndianAlaskaNative + asian + blackAfricanAmerican + nativeHawaiianOrOtherPacificIslander + other + white + unknowns;
// console.log(totalPopulation)
  useEffect(() => {
    calculate();
  }, [data, dataType]);

  const newData = [
    [
      "American Indian Alaska Native",
      parseFloat(((americanIndianAlaskaNative/totalPopulation)* 100).toFixed(2)),
    ],
    ["Asian", parseFloat(((asian/totalPopulation)* 100).toFixed(2))],
    ["Black or African American",      parseFloat(((blackAfricanAmerican/totalPopulation)* 100).toFixed(2))],
    [
      "Native Hawaiian or other Pacific Islander",
      parseFloat(((nativeHawaiianOrOtherPacificIslander/totalPopulation)* 100).toFixed(2))
    ],
    ["other", parseFloat(((other/totalPopulation)* 100).toFixed(2))],
    ["White", parseFloat(((white/totalPopulation)* 100).toFixed(2))],
    ["Unknowns", parseFloat(((unknowns/totalPopulation)* 100).toFixed(2))],
  ];

  const options = {
    chart: {
      type: "column",
      zoomType: "xy",
    },

    column: {
      dataLabels: {
        enabled: true,
      },
    },

    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    title: {
      text: "Race Distribution of Positive Tests",
      style: {
        fontSize: '14px'
    }
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      type: "category",
      labels: {
        style: {
          fontSize: "9px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      tickAmount: 10,
      title: {
        text: "Percentage",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: "%",
    },
    series: [
      {
        name: "Percentage",
        data: newData || "",
        color: "rgb(52, 181, 191)",
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default LabsRaceDist;
