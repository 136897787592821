import React from "react";
import HotelIcon from "@material-ui/icons/Hotel";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AddLocationIcon from "@mui/icons-material/AddLocation";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "black",
  },
  text: {
    marginLeft: "-20px",
    color: "rgb(8,87,97)",
    fontStyle: "bold",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
    fontSize: "25px",
  },
});

function Ventilators({ data }) {
  const classes = useStyles();

  return (
    <div>
      {/* Total beds card */}
      <h6 className="card-header bg-light">
        <ListItem className={classes.root}>
          <ListItemIcon>
            <AddLocationIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.text}>
            VENTILATORS USAGE
          </ListItemText>
        </ListItem>{" "}
      </h6>{" "}
      <div className="row pt-4 pb-4">
        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.TotalVentilators}
              </h2>
              <p className="card-text text-muted">Total Ventilators</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.InUseVentilators}
              </h2>
              <p className="card-text text-muted">Ventilators Occupied</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.Covid19Ventilators}
              </h2>
              <p className="card-text text-muted">
                Occupied by Covid-19 Patients
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold text-info">
                {" "}
                {data.AvailableVentilators}
              </h2>
              <p className="card-text text-muted">Available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ventilators;
