import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ScienceIcon from "@mui/icons-material/Science";
import DoneIcon from "@mui/icons-material/Done";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TimelineIcon from "@mui/icons-material/Timeline";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "black",
  },
  text: {
    marginLeft: "-20px",
    color: "rgb(8,87,97)",
    fontStyle: "bold",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
    fontSize: "25px",
  },
  iconBox: {
    color: "#b5eaf5",
    fontSize: "large",
    border: " 0px solid rgb(13,202,240)",
    borderRadius: "100%",
    backgroundColor: "rgb(8,87,97)",
    margin: "3px",
    padding: "5px",
  },
});

function TotalLabsData({ data }) {
  const classes = useStyles();

  const labsTotals = {
    TotalLabsOrdered: 0,
    TotalLabsCompleted: 0,
    TotalTestedPositive: 0,
  };

  data.labsTotals.forEach((labObj) => {
    labsTotals.TotalLabsOrdered += parseInt(labObj.TotalLabsOrdered, 10);
    labsTotals.TotalLabsCompleted += parseInt(labObj.TotalLabsCompleted, 10);
    labsTotals.TotalTestedPositive += parseInt(labObj.TotalTestedPositive, 10);
  });

  const percentageValue = `${Math.round((labsTotals.TotalTestedPositive * 100) / labsTotals.TotalLabsOrdered)}%`;
  // console.log(data)

  return (
    <div>
      {/* Total beds card */}
      <h6 className="card-header bg-light">
        <ListItem className={classes.root}>
          <ListItemIcon>
            <ScienceIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.text}>
            LABS
          </ListItemText>
        </ListItem>{" "}
      </h6>{" "}
      <div className="row pt-4 pb-4">
        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <PlaylistAddIcon fontSize="large" className={classes.iconBox} />
              <h2 className="card-title font-weight-bold">
                {" "}
                {labsTotals.TotalLabsOrdered}
              </h2>
              <p className="card-text text-muted">Total Labs Ordered</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <DoneIcon fontSize="large" className={classes.iconBox} />
              <h2 className="card-title font-weight-bold">
                {" "}
                {labsTotals.TotalLabsCompleted}
              </h2>
              <p className="card-text text-muted">Total Labs Completed</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <PriorityHighIcon fontSize="large" className={classes.iconBox} />
              <h2 className="card-title font-weight-bold">
                {" "}
                {labsTotals.TotalTestedPositive}
              </h2>
              <p className="card-text text-muted">Total Tested Positive</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <TimelineIcon fontSize="large" className={classes.iconBox} />
              <h2 className="card-title font-weight-bold">
                {" "}
                {percentageValue}
              </h2>
              <p className="card-text text-muted">Percentage Positive</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalLabsData;
