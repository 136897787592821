import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

export default function IpCovidHospitalizations({ data }) {
  const result1 = data.map(({ admits, unix_date }) => [
    parseFloat(unix_date + "000"),
    parseFloat(admits),
  ]);
  const result2 = data.map(({ discharges, unix_date }) => [
    parseFloat(unix_date + "000"),
    parseFloat(discharges),
  ]);

  const options = {
    rangeSelector: {
      selected: 1,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Admissions, Discharges",
      },
    },
    series: [
      {
        name: "Admissions",
        data: result1,
        color: "rgb(52, 181, 191)",
        tooltip: {
          valueDecimals: 0,
        },
      },
      {
        name: "Discharges",
        data: result2,
        color: "#5D7092",
        tooltip: {
          valueDecimals: 0,
        },
      },
    ],
  };

  return (
    <div>
      <h5 style={{ textAlign: "center" }}> IP Covid Hospitalization </h5>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
    </div>
  );
}
