import React, { useMemo, useState, useEffect } from "react";
import HotelIcon from "@material-ui/icons/Hotel";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useOktaAuth } from "@okta/okta-react";
import LinearProgress from "@mui/material/LinearProgress";
import NotFound from "../../Alerts/NotFound";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import BedCapacityGroup from "./BedCapacityGroup";
import { Refresh } from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "rgb(8,87,97)",
  },
  text: {
    marginLeft: "-20px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
  },
});

export default function LiveBedCapacity() {
  const [data, setData] = useState([]);
  const [bedsData, setBedsData] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const classes = useStyles();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);
  const getData = async () => {
    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_API + "/bedlivecapacity_v2";
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // setData(res.data.per_hospital_summary);
          setBedsData(res.data);
          setLoading(false);
          setButtonLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
      updateTime();
    }, 7200000);

    return () => clearInterval(interval);
  }, []);

  const refreshData = (e) => {
    setButtonLoading(true);
    getData();
  };
  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  if (handleGroupAuth("Nebraska Bed Capacity Dashboard Users")) {
    return (
      <div>
        {isMobileDevice ? (
          <div className="mt-3 pt-5">
            <ListItem>
              <ListItemIcon>
                <HotelIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText>
                <div className="d-inline">
                  <span style={{ fontWeight: "bold" }}>
                    Bed Capacity Dashboard
                  </span>
                  <span className="livebadge pulsate ml-2">NRT</span>{" "}
                  <div style={{ fontSize: "small" }}>
                    {" "}
                    Last Updated : {updateTime()}
                  </div>
                  <LoadingButton
                    className="mt-2"
                    size="small"
                    onClick={(e) => refreshData(e)}
                    loading={buttonLoading}
                    loadingPosition="end"
                    variant="outlined"
                    endIcon={<Refresh />}
                  >
                    {" "}
                    Refresh
                  </LoadingButton>
                </div>
              </ListItemText>
            </ListItem>
            <div className="alert alert-light m-2">
              Authorized users agree information contained within the
              CyncHealth's Dashboards is confidential and agree to not
              disseminate such information contained therein.
            </div>
          </div>
        ) : (
          <div className="mt-5 pt-4">
            <ListItem className={classes.root}>
              <ListItemIcon>
                <HotelIcon fontSize="large" className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className={classes.text}>Bed Capacity Dashboard</span>
                <div className="d-inline">
                  <span className="livebadge pulsate ml-2">NRT</span>{" "}
                  <span className=" ml-3"> Last Updated : {updateTime()}</span>
                  <LoadingButton
                    className=" ml-2"
                    size="small"
                    onClick={(e) => refreshData(e)}
                    loading={buttonLoading}
                    loadingPosition="end"
                    variant="outlined"
                    endIcon={<Refresh />}
                  >
                    {" "}
                    Refresh
                  </LoadingButton>
                </div>
              </ListItemText>
            </ListItem>
            <div className="alert alert-light mt-3" role="alert">
              Authorized users agree information contained within the
              CyncHealth's Dashboards is confidential and agree to not
              disseminate such information contained therein.
            </div>
          </div>
        )}

        {/* <Paper elevation={0} style={{ padding: "10px", marginBottom: "20px" }}>
          {loading ? (
            <LinearProgress />
          ) : (
            <TotalAvailableBeds data={bedsData} />
          )}
        </Paper> */}

        <Paper elevation={2} style={{ padding: "8px", height: "auto" }}>
          {loading ? <LinearProgress /> : <BedCapacityGroup data={bedsData} />}
        </Paper>

        <div className="alert alert-light mt-4" role="alert">
          <strong>Disclaimer: </strong>
          <p>
          This Dashboard is provided as a service to Authorized
          Users pursuant to applicable Data Sharing and Participation Agreements
          and in compliance with all applicable laws. Users are restricted from
          the following activities pursuant to the Agreements: make this Service
          available to any other person, entity or business; copy,
          reverse-engineer, decompile, or disassemble the System or data
          contained herein; and modify or combine the System with any other
          software or services not provided or approved by CyncHealth. 
          </p>
          <p>
          In addition, this information may be confidential or proprietary and
          should be withheld from public record requests under Nebraska Revised
          Statues § 84-712.05 (specifically §84-712.05(3)).
          </p>
          <p>
          Data shown calculates beds occupied by total beds minus available
          beds. Occupied bed are defined as those beds that currently are filled
          with a patient. Available beds shows the difference between total
          staffed beds and total occupied beds pertaining to the category. This
          near-real-time bed occupancy data comes from the state HIE
          (CyncHealth) and only contains those facilities that are actively
          sharing data with CyncHealth.
          Data is near-real time with possible delays (varying by minutes) that
          may occur due to the time taken to ingest data from facility until the
          time it can be displayed by CyncHealth. Bed types are obtained from
          the patient Admission, Discharge, Transfer (ADT) events and can only
          reflect the data as shared by the facility. Bed types definitions can
          be found in the frequently asked questions under the “Support” tab.
          </p>
<p>
          NRT - Near real-time with a delay of 5-15 minutes.
          </p>
       
            <li><sup>1</sup> Represents the total staffed bed from
          EMResource(Juvare) – data updated daily.</li>
            <li><sup>2</sup> Represents the total licensed bed when staffed bed is not
          available in from EMResource – data updated from the DHHS Rosters
          (last updated December)</li>
            <li><sup>3</sup> Represents the total of room/bed combinations from
          CyncHealth Health Information Exchange transactions – data updated
          near-real-time.</li>
    
          
          <br></br> 
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}
