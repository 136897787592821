import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/charts";

export default function CovidChronicConditionsDist() {
  var data = [
    {
      type: "American Indian or Alaska Native",
      sales: 30,
    },
    {
      type: "Asian",
      sales: 60,
    },
    {
      type: "Black or African American",
      sales: 40,
    },
    {
      type: "Native Hawaiian or other",
      sales: 0,
    },
    {
      type: "Other race",
      sales: 100,
    },
    {
      type: "White",
      sales: 700,
    },
  ];

  var config = {
    data: data,
    height: 350,
    xField: "type",
    yField: "sales",
    color: "rgb(52, 181, 191)",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: { alias: "Total Percentage" },
      sales: { alias: "Total Percentage" },
    },
  };

  return (
    <div>
      <h5 style={{ textAlign: "center" }}> Race Distribution</h5>
      <Column {...config} />
    </div>
  );
}
