import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import data from "../../../../../public/labsMockData.json";
import exporting from "highcharts/modules/exporting";
import indicators from "highcharts/indicators/indicators";
import regressions from "highcharts/indicators/regressions";
import trendline from "highcharts/indicators/trendline";
import { useEffect } from "react";
import highchartsAccessibility from "highcharts/modules/accessibility";

import { RsvpOutlined } from "@mui/icons-material";
import { useRef } from "react";
indicators(Highcharts);
regressions(Highcharts);
// import HC_Regression from  "highcharts-regression";
trendline(Highcharts);
exporting(Highcharts);
highchartsAccessibility(Highcharts);

export default function LabsTotalPatsTestedPositive({ data, dataType }) {
  const data1 = data.sort((a, b) => a.Date - b.Date);
  const result1 = data1.map(({ UniquePatientsTestedPositiveRsv, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(UniquePatientsTestedPositiveRsv),
  ]);
  const result11 = data1.map(({ UniquePatientsLabsOrderedRsv, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(UniquePatientsLabsOrderedRsv),
  ]);
  const result2 = data1.map(
    ({ UniquePatientsTestedPositiveInfluenza, Date }) => [
      parseFloat(Date + "000"),
      parseFloat(UniquePatientsTestedPositiveInfluenza),
    ]
  );
  const result22 = data1.map(({ UniquePatientsLabsOrderedInfluenza, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(UniquePatientsLabsOrderedInfluenza),
  ]);
  const result3 = data1.map(({ UniquePatientsTestedPositiveCovid, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(UniquePatientsTestedPositiveCovid),
  ]);
  const result33 = data1.map(({ UniquePatientsLabsOrderedCovid, Date }) => [
    parseFloat(Date + "000"),
    parseFloat(UniquePatientsLabsOrderedCovid),
  ]);

  useEffect(() => {
    seriesDecided(dataType);
  }, [dataType, data]);

  const rsvLine = [
    {
      name: "RSV Positive",
      id: "rsvPositive",
      data: result1,
      color: "#f3aa1c",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "rsvPositive",
      zIndex: -2,
      color: "#f9d58e",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
    {
      name: "RSV Total",
      id: "rsvTotal",
      data: result11,
      color: "#926611",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "rsvTotal",
      zIndex: -2,
      color: "#f9d58e",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
  ];

  const influLine = [
    {
      name: "Influenza Positive",
      id: "influenzaPositive",
      data: result2,
      color: "#83bf41",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "influenzaPositive",
      zIndex: -2,
      color: "#cde5b3",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
    {
      name: "Influenza Total",
      id: "influenzaTotal",
      data: result22,
      color: "#4f7327",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "influenzaTotal",
      zIndex: -2,
      color: "#cde5b3",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
  ];
  const covidLine = [
    {
      name: "Covid Positive",
      data: result3,
      id: "covidPositive",
      color: "#09afd9",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "covidPositive",
      zIndex: -2,
      color: "#b5e7f4",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
    {
      name: "Covid Totals",
      id: "covidTotal",
      data: result33,
      color: "#056982",
      tooltip: {
        valueDecimals: 0,
      },
      marker: {
        enabled: true,
        radius: 3,
      },
    },
    {
      type: "trendline",
      linkedTo: "covidTotal",
      zIndex: -2,
      color: "#b5e7f4",
      marker: {
        enabled: false,
      },
      tooltip: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "longdash",
    },
  ];

  const seriesDecided = (dataType) => {
    // chart && chart.destroy()
    let seriesFin = [];
    if (
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      seriesFin = rsvLine.map((a) => a);
    }

    if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0 &&
      dataType.indexOf("Covid") < 0
    ) {
      seriesFin = influLine.map((a) => a);
    }
    if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") < 0 &&
      dataType.indexOf("Rsv") < 0
    ) {
      seriesFin = covidLine.map((a) => a);
    }
    if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1
    ) {
      const rsvCovidInlu = rsvLine.concat(covidLine, influLine);
      seriesFin = rsvCovidInlu.map((a) => a);
    }
    if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") < 0
    ) {
      const influCovid = covidLine.concat(influLine);
      seriesFin = influCovid.map((a) => a);
    }
    if (
      dataType.indexOf("Influenza") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Covid") < 0
    ) {
      const influRsv = rsvLine.concat(influLine);
      seriesFin = influRsv.map((a) => a);
    }
    if (
      dataType.indexOf("Covid") > -1 &&
      dataType.indexOf("Rsv") > -1 &&
      dataType.indexOf("Influenza") < 0
    ) {
      const covRsv = rsvLine.concat(covidLine);
      seriesFin = covRsv.map((a) => a);
    }
    return seriesFin;
  };

  const options = {
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    highchartsAccessibility: {
      enabled: "true",
    },
    chart: {
      zoomType: "x",
    },
    title: {
      text: "Total Patients vs. Tested Positive",
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 0,
      y: 100,
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      buttons: [
        {
          type: "week",
          count: 1,
          text: "1w",
        },
        {
          type: "week",
          count: 2,
          text: "2w",
        },
        {
          type: "month",
          count: 1,
          text: "1m",
        },
        {
          type: "all",
          count: 1,
          text: "All",
        },
      ],

      selected: 0,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      tickInterval: 100,
      opposite: false,
      title: {
        text: "Total Patients Tested Positive",
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    xAxis: {
      type: "datetime",
      minRange: 3600 * 1000 * 24, // one day
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: seriesDecided(dataType),
  };

  return (
    <div>
      <HighchartsReact
        allowChartUpdate={true}
        immutable={true}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
}
