import React, { useState } from "react";

import GeoDistNe from "./GeoDistNe";

export default function MapGeoDistNe() {
  const [content, setContent] = useState("");
  return (
    <div>
      <GeoDistNe setTooltipContent={setContent} />
      {/* <ReactTooltip>{content}</ReactTooltip> */}
    </div>
  );
}
