import React from "react";
import "./Welcome.css";
export default function Slide() {
  return (
    <div>
      <div
        style={{
          zIndex: "1",
          backgroundColor: "lightblue",
        }}
        id="carouselExampleInterval"
        className="carousel slide"
        data-ride="carousel"
        data-interval="20000"
      >
        <div className="carousel-inner">
          <div className="carousel-item active" data-interval="20000">
            <div className="gradBack"> 
            <div className="carousel-caption" style={{ top: "270px" }}>
              <h4>
                State Senator Sara Howard praises CyncHealth’s COVID-19 data
                monitoring efforts
              </h4>

              <div style={{ fontStyle: "italic", fontSize: "12px" }}>
                {" "}
                Apr 26, 2020{" "}
              </div>

              <p>
                CyncHealth is rapidly deploying COVID-19 data-monitoring efforts
                to make a difference in keeping Nebraskans healthy.
              </p>
              <a
                className="badge badge-warning"
                href="https://omaha.com/opinion/midlands-voices-info-sharing-boosts-nebraskas-fight-against-coronavirus/article_482893a4-590c-5c0f-9751-782be98c326e.html"
                target="_blank"
              >
                read more
              </a>
            </div>
            </div>
          </div>
          <div className="carousel-item" data-interval="20000">
            <div className="gradBack"> 
            <div className="carousel-caption" style={{ top: "270px" }}>
              <h4>CyncHealth can help ease required reporting process</h4>
              <div style={{ fontStyle: "italic", fontSize: "12px" }}>
                {" "}
                Mar 20, 2020{" "}
              </div>

              <p>
                {" "}
                In coordination with the DHHS, we are requesting assistance in
                the timely reporting of information in order to aid the state of
                Nebraska in providing essential responses to the COVID-19
                outbreak affecting our communities.
              </p>
              <a
                className="badge"
                href="https://cynchealth.org/nehii-can-help-ease-required-reporting-process/"
                target="_blank"
              >
                read more
              </a>
            </div>{" "}
            </div>
          </div>
          <div className="carousel-item" data-interval="20000">
            <div className="gradBack"> 
            <div className="carousel-caption" style={{ top: "270px" }}>
              <h3>
                DHHS requests all health care facilities, health care providers
                and labs connect to CyncHealth
              </h3>
              <div style={{ fontStyle: "italic", fontSize: "12px" }}>
                Mar 18, 2020
              </div>

              <p>
                Connections to CyncHealth would be at no cost to the facility,
                provider, or lab and will improve and ease the required
                reporting process.
              </p>
              <a
              style={{textAlign:'center'}}
                className="badge badge-warning"
                href="https://cynchealth.org/dhhs-requests-all-health-care-facilities-health-care-providers-and-labs-connect-to-nehii/"
                target="_blank"
              >
                read more
              </a>
            </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleInterval"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleInterval"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
}
