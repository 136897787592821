import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const AgeGenderDist = ({ data, selected }) => {
  const categoriesRsv = ["Below 1", "1-2", "3-4", "5-11", "12-18", "Above 18"];

  const categoriesInfluenza = [
    "Below 18",
    "19-30",
    "31-40",
    "41-50",
    "51-65",
    "Age-Unknown",
  ];

  const categoriesCovid = [
    "Below 18",
    "19-30",
    "31-40",
    "41-50",
    "51-65",
    "Age-Unknown",
  ];

  const dataRsv = data.sort(
    (a, b) => categoriesRsv.indexOf(a.Age) - categoriesRsv.indexOf(b.Age)
  );
  const dataInflu = data.sort(
    (a, b) =>
      categoriesInfluenza.indexOf(a.Age) - categoriesInfluenza.indexOf(b.Age)
  );
  const dataCovid = data.sort(
    (a, b) => categoriesCovid.indexOf(a.Age) - categoriesCovid.indexOf(b.Age)
  );

  const categories = (
    selected === "Influenza"
      ? dataInflu
      : selected === "Covid"
      ? dataCovid
      : dataRsv
  ).map((c) => {
    return c.Age;
  });

  const averageMale = (
    selected === "Influenza"
      ? dataInflu
      : selected === "Covid"
      ? dataCovid
      : dataRsv
  ).map((m) => {
    return parseFloat(m.AverageMale * 1000);
  });

  const averageFemale = (
    selected === "Influenza"
      ? dataInflu
      : selected === "Covid"
      ? dataCovid
      : dataRsv
  ).map((m) => {
    return parseFloat(m.AverageFemale * 1000);
  });

  const options = {
    chart: {
      type: "bar",
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      accessibility: {
        description: "Average Male",
      },
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        formatter: function () {
          return Math.abs(this.value) + "%";
        },
        overflow: "justify",
      },
      accessibility: {
        description: "Average population",
      },
    },

    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(Math.abs(this.point.y), 3) + "%";
          },
        },
      },
    },

    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.series.name +
          ", age-group " +
          this.point.category +
          "</b><br/>" +
          "Average: " +
          Highcharts.numberFormat(Math.abs(this.point.y), 3) +
          "%"
        );
      },
    },

    series: [
      {
        name: "Male",
        data: averageMale,
        color: "rgb(52, 181, 191)",
      },
      {
        name: "Female",
        data: averageFemale,
        color: "#5D7092",
      },
      // {
      //   name: "Other",
      //   data: averageOther,
      //   color: "rgb(25, 100, 191)",
      // },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default AgeGenderDist;
