import React, { useState, useEffect } from "react";
import ScienceIcon from "@mui/icons-material/Science";
import Paper from "@mui/material/Paper";
import TotalLabsData from "./TotalLabsData";
import UniquePatients from "./UniquePatients";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ExampleLayout from "./labsCharts/LabsReflowLayout";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import LinearProgress from "@mui/material/LinearProgress";
import NotFound from "../../Alerts/NotFound";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Error, Refresh, SyncProblemOutlined } from "@material-ui/icons";
import LoadingButton from "@mui/lab/LoadingButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery } from "react-responsive";
import { Divider } from "@mui/material";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "rgb(8,87,97)",
    marginBottom: "20px",
  },
  text: {
    marginLeft: "-20px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
  },
});

export default function Labs() {
  const [rsvData, setRsvData] = useState([]);
  const [covidData, setCovidData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [influenzaData, setInfluenzaData] = useState([]);
  const [filter, setFilter] = useState("RSV");
  const [loading, setLoading] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const classes = useStyles();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const getRsvData = async () => {
    setShowError(false);
    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_API + "/labs_rsv";
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res, "pass");
            setRsvData(res.data);
            setLoading(false);
            setButtonLoading(false);
          } else {
            console.log(res, "error");
            setButtonLoading(false);
            setLoading(false);
            setShowError(true);
          }
        });
    } catch (e) {
      setLoading(false);
      setShowError(true);
      setButtonLoading(false);
      console.log(e);
    }
  };
  const getCovidData = async () => {
    setShowError(false);
    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_API + "/labs_covid";
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res, "pass");
            setCovidData(res.data);
            setLoading(false);
            setButtonLoading(false);
          } else {
            console.log(res, "error");
            setLoading(false);
            setButtonLoading(false);
            setShowError(true);
          }
        });
    } catch (e) {
      setLoading(false);
      setShowError(true);
      setButtonLoading(false);
      console.log(e);
    }
  };

  const getInfluenzaData = async () => {
    setShowError(false);
    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_API + "/labs_influenza";
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res, "pass");
            setInfluenzaData(res.data);
            setLoading(false);
            setButtonLoading(false);
          } else {
            console.log(res, "error");
            setButtonLoading(false);
            setLoading(false);
            setShowError(true);
          }
        });
    } catch (e) {
      setLoading(false);
      setButtonLoading(false);
      setShowError(true);
      console.log(e);
    }
  };

  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };
  const handleChange = (event) => {
    const val = event.target.value;
    if (val === "RSV") {
      getRsvData();
    } else if (val === "Influenza") {
      getInfluenzaData();
    } else if (val === "Covid") {
      getCovidData();
    }

    setFilter(val);
  };
  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  useEffect(() => {
    getRsvData();
    const interval = setInterval(() => {
      handleChange(e);
      updateTime();
    }, 720000);

    return () => clearInterval(interval);
  }, []);

  const refreshData = (e) => {
    if (filter === "RSV") {
      getRsvData();
    } else if (filter === "Covid") {
      getCovidData();
    } else if (filter === "Influenza") {
      getInfluenzaData();
    } else {
      getRsvData();
    }

    setButtonLoading(true);
    getRsvData();
  };

  const lookForData = (selected) => {
    if (selected === "RSV") {
      return rsvData.length > 0;
    } else if (selected === "Influenza") {
      return influenzaData.length > 0;
    } else if (selected === "Covid") {
      return covidData.length > 0;
    } else {
      return false;
    }
  };

  const dataDuration = (selected) => {
    if (selected === "RSV") {
      const dates = rsvData
        ? rsvData[0].orderedVsCompleted.map((d) => {
            return d.date;
          })
        : "";
      const fDate = dates.reduce((a, b) => {
        return Math.max(a, b);
      });
      const lDate = dates.reduce((a, b) => {
        return Math.min(a, b);
      });
      const firstDate = new Date(fDate * 1000).toString();
      const lastDate = new Date(lDate * 1000).toString();
      const finTS = { fd: firstDate, ld: lastDate };
      return finTS;
    } else if (selected === "Influenza") {
      const dates = influenzaData
        ? influenzaData[0].orderedVsCompleted.map((d) => {
            return d.date;
          })
        : "";
      const fDate = dates.reduce((a, b) => {
        return Math.max(a, b);
      });
      const lDate = dates.reduce((a, b) => {
        return Math.min(a, b);
      });
      const firstDate = new Date(fDate * 1000).toString();
      const lastDate = new Date(lDate * 1000).toString();
      const finTS = { fd: firstDate, ld: lastDate };
      return finTS;
    } else if (selected === "Covid") {
      const dates = covidData
        ? covidData[0].orderedVsCompleted.map((d) => {
            return d.date;
          })
        : "";
      const fDate = dates.reduce((a, b) => {
        return Math.max(a, b);
      });
      const lDate = dates.reduce((a, b) => {
        return Math.min(a, b);
      });
      const firstDate = new Date(fDate * 1000).toString();
      const lastDate = new Date(lDate * 1000).toString();
      const finTS = { fd: firstDate, ld: lastDate };
      return finTS;
    } else {
      return "";
    }
  };

  if (handleGroupAuth("Nebraska Labs Dashboard Users")) {
    return (
      <div>
        {isMobileDevice ? (
          <div className="mt-3 pt-5">
            <ListItem>
              <ListItemIcon>
                <ScienceIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText>
                <div className="d-inline">
                  <span style={{ fontWeight: "bold" }}> Labs Dashboard</span>
                  <div style={{ fontSize: "small" }}>
                    {" "}
                    Last Updated : {updateTime()}
                  </div>
                  <LoadingButton
                    className="mt-2"
                    size="small"
                    onClick={(e) => refreshData(e)}
                    loading={buttonLoading}
                    loadingPosition="end"
                    variant="outlined"
                    endIcon={<Refresh />}
                  >
                    {" "}
                    Refresh
                  </LoadingButton>
                </div>
              </ListItemText>
            </ListItem>
            <div>
              <Accordion sx={{ marginBottom: 2, boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography
                    variant="subtitle2"
                    color="#666666"
                    paddingLeft={-1}
                  >
                    {" "}
                    Disclaimer{" "}
                  </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <Typography variant="body2">
                    Real-time lab data obtained from facilities that have
                    integrated with lab hub and CyncHealth. Real-time lab data
                    also obtained from HIE data which includes data from
                    CyncHealth participants with executed Participant Agreements
                    and Business Associate Agreements. Lab data from the
                    attached facilities is currently available in HIE.
                    <br /> <br />
                    Authorized users agree information contained within the
                    CyncHealth's Dashboards is confidential and agree to not
                    disseminate such information contained therein.{" "}
                  </Typography>
                </AccordionDetails>
                <Divider />
              </Accordion>
            </div>
          </div>
        ) : (
          <div className="mt-5 pt-4">
            <ListItem className={classes.root}>
              <ListItemIcon>
                <ScienceIcon fontSize="large" className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className={classes.text}> Labs Dashboard</span>
                <div className="d-inline">
                  <span className=" ml-3"> Last Updated : {updateTime()}</span>
                  <LoadingButton
                    className=" ml-2"
                    size="small"
                    onClick={(e) => refreshData(e)}
                    loading={buttonLoading}
                    loadingPosition="end"
                    variant="outlined"
                    endIcon={<Refresh />}
                  >
                    {" "}
                    Refresh
                  </LoadingButton>
                </div>
              </ListItemText>
            </ListItem>
            <div>
              <Accordion sx={{ marginBottom: 2, boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography
                    variant="subtitle1"
                    color="#666666"
                    paddingLeft={-1}
                  >
                    {" "}
                    Disclaimer{" "}
                  </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <Typography variant="body3">
                    Real-time lab data obtained from facilities that have
                    integrated with lab hub and CyncHealth. Real-time lab data
                    also obtained from HIE data which includes data from
                    CyncHealth participants with executed Participant Agreements
                    and Business Associate Agreements. Lab data from the
                    attached facilities is currently available in HIE.
                    <br /> <br />
                    Authorized users agree information contained within the
                    CyncHealth's Dashboards is confidential and agree to not
                    disseminate such information contained therein.{" "}
                  </Typography>
                </AccordionDetails>
                <Divider />
              </Accordion>
            </div>
          </div>
        )}

        <Paper elevation={0} style={{ padding: "10px", marginBottom: "20px" }}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Labs Type :
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={filter}
              onChange={(event) => handleChange(event)}
            >
              <FormControlLabel value="RSV" control={<Radio />} label="RSV" />
              <FormControlLabel
                value="Influenza"
                control={<Radio />}
                label="Influenza"
              />
              <FormControlLabel
                value="Covid"
                control={<Radio />}
                label="Covid"
              />
            </RadioGroup>
          </FormControl>
          <Paper
            style={{ backgroundColor: "#e8e9e9", padding: "4px" }}
            elevation={0}
          >
            {" "}
            {lookForData(filter) ? (
              <Typography fontSize={14}>
                {" "}
                Data Timeline : {dataDuration(filter).ld} - to -{" "}
                {dataDuration(filter).fd}
              </Typography>
            ) : (
              ""
            )}{" "}
          </Paper>
        </Paper>

        {showError && lookForData(filter) === false ? (
          <Paper
            elevation={2}
            style={{ padding: "50px", marginBottom: "20px" }}
          >
            <Typography>
              We are having trouble in loading data! Please try again after some
              time.
            </Typography>
            <SyncProblemOutlined style={{ width: "200px", height: "200px" }} />
          </Paper>
        ) : (
          " "
        )}
        <Paper elevation={0} style={{ padding: "10px", marginBottom: "20px" }}>
          {loading ? (
            <LinearProgress />
          ) : lookForData(filter) ? (
            <TotalLabsData
              data={
                filter === "RSV"
                  ? rsvData[0]
                  : filter === "Covid"
                  ? covidData[0]
                  : filter === "Influenza"
                  ? influenzaData[0]
                  : ""
              }
            />
          ) : (
            ""
          )}
        </Paper>
        <Paper elevation={0} style={{ padding: "10px", marginBottom: "20px" }}>
          {loading ? (
            <LinearProgress />
          ) : lookForData(filter) ? (
            <UniquePatients
              data={
                filter === "RSV"
                  ? rsvData[0]
                  : filter === "Covid"
                  ? covidData[0]
                  : filter === "Influenza"
                  ? influenzaData[0]
                  : ""
              }
            />
          ) : (
            ""
          )}
        </Paper>

        {loading ? (
          <LinearProgress />
        ) : lookForData(filter) ? (
          <ExampleLayout
            data={
              filter === "RSV"
                ? rsvData[0]
                : filter === "Covid"
                ? covidData[0]
                : filter === "Influenza"
                ? influenzaData[0]
                : ""
            }

            selectedFilter={filter}
          />
        ) : (
          ""
        )}

        <div className="alert alert-light mt-4" role="alert">
          Disclaimer: Disclosure of identifiable health information is made
          pursuant to Nebraska Executive Order No. 20-15 and the federal
          <a
            href="https://www.hhs.gov/sites/default/files/notification-enforcement-discretion-hipaa.pdf?language=es"
            target="_blank"
          >
            HHS Notification of Enforcement Discretion under HIPAA{" "}
          </a>
          , as such use is made to individuals who are in a position to prevent
          and lessen a serious and imminent threat to the health and safety of a
          person or the public.
          <br /> Unknown records not included in the calculation of Age and
          Gender, Race, Ethnicity graphs
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}
