/* eslint-disable */
import React, { Component } from "react";
import "./Welcome.css";
import Slide from "./Slide";
import Parallax from "./Parallax";
import Button from "@mui/material/Button";
import { withOktaAuth } from "@okta/okta-react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export default withOktaAuth(
  class WelcomeMessage extends Component {
    constructor(props) {
      super(props);
      this.login = this.login.bind(this);
    }
    async login() {
      await this.props.oktaAuth.signInWithRedirect({ originalUri: "/menu" });
    }
    render() {
      if (this.props.authState?.isAuthenticated) {
        return null;
      } else {
        return (
          <>
            <Parallax />
            <div className="landing-page-cont-group ">
              <div className="landing-page-cont">
                <Slide />
              </div>
              <div className="landing-page-cont" id="landing-page-cont-main">
                <div
                  style={{ paddingLeft: "5rem" }}
                  className="landing-page-content"
                >
                  <h2 id="landing-page-heading">
                    {" "}
                    Welcome to Nebraska Dashboard
                  </h2>
                  <Button
                    variant="contained"
                    // color= "default"
                    id="logout-button"
                    onClick={this.login}
                    endIcon={<ExitToAppIcon />}
                  >
                    Login
                  </Button>
                  <div style={{ marginTop: "50px" }}>
                    <span>
                      {" "}
                      Powered By : {"  "}
                      <img
                        src={process.env.PUBLIC_URL + "/CHicon.png"}
                        width="35"
                        height="35"
                        alt="logo"
                      ></img>{" "}
                      CyncHealth
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  }
);
