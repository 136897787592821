import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const RaceDist = ({ data }) => {
  const {
    AmericanIndianAlaskaNative,
    Asian,
    BlackAfricanAmerican,
    NativeHawaiianOrOtherPacificIslander,
    other,
    white,
  } = data;

  const newData = [
    [
      "AmericanIndianAlaskaNative",
      Math.floor(AmericanIndianAlaskaNative * 1000),
    ],
    ["Asian", Math.floor(Asian * 1000)],
    ["BlackAfricanAmerican", Math.floor(BlackAfricanAmerican * 1000)],
    [
      "NativeHawaiianOrOtherPacificIslander",
      Math.floor(NativeHawaiianOrOtherPacificIslander * 1000),
    ],
    ["other", Math.floor(other * 100)],
    ["white", Math.floor(white * 100)],
  ];

  const options = {
    chart: {
      type: "column",
    },

    column: {
      dataLabels: {
        enabled: true,
      },
    },

    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    title: {
      text: "",
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      type: "category",
      labels: {
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Average",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: "%",
    },
    series: [
      {
        name: "Average",
        data: newData || "",
        color: "rgb(52, 181, 191)",
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default RaceDist;
