import React, { useState, useEffect } from "react";

const validFips = [
  "31001",
  "31003",
  "31005",
  "31007",
  "31009",
  "31011",
  "31013",
  "31015",
  "31017",
  "31019",
  "31021",
  "31023",
  "31025",
  "31027",
  "31029",
  "31031",
  "31033",
  "31035",
  "31037",
  "31039",
  "31041",
  "31043",
  "31045",
  "31047",
  "31049",
  "31051",
  "31053",
  "31055",
  "31057",
  "31059",
  "31061",
  "31063",
  "31065",
  "31067",
  "31069",
  "31071",
  "31073",
  "31075",
  "31077",
  "31079",
  "31081",
  "31083",
  "31085",
  "31087",
  "31089",
  "31091",
  "31093",
  "31095",
  "31097",
  "31099",
  "31101",
  "31103",
  "31105",
  "31107",
  "31109",
  "31111",
  "31113",
  "31115",
  "31117",
  "31119",
  "31121",
  "31123",
  "31125",
  "31127",
  "31129",
  "31131",
  "31133",
  "31135",
  "31137",
  "31139",
  "31141",
  "31143",
  "31145",
  "31147",
  "31149",
  "31151",
  "31153",
  "31155",
  "31157",
  "31159",
  "31161",
  "31163",
  "31165",
  "31167",
  "31169",
  "31171",
  "31173",
  "31175",
  "31177",
  "31179",
  "31181",
  "31183",
  "31185",
];

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};
const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json";

const GeoDistNe = ({ data = [], colorKey, setTooltipContent }) => {
  // const colorScale = scaleQuantile()
  //   .domain(data.map((d) => d[colorKey]))
  //   .range([
  //     "#ffedea",
  //     "#ffcec5",
  //     "#ffad9f",
  //     "#ff8a75",
  //     "#ff5533",
  //     "#e2492d",
  //     "#be3d26",
  //     "#9a311f",
  //     "#782618",
  //   ]);

  const projectionConfig = {
    scale: 5000,
    rotation: [100, 0, 0],
    center: [-99.5, 41],
  };

  return (
    <>
      <h5 style={{ textAlign: "center" }}>
        {" "}
        Geagraphic Distribution of SNF and Hospital Locations in Nebraska
      </h5>
      {/* <ComposableMap
        data-tip=""
        projectionConfig={projectionConfig}
        style={{
          transform: "skew(27deg)",
          marginTop: "-150px",
          marginBottom: "-300px",
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies
              .filter((loc) => {
                if (validFips.includes(loc.id)) return true;
              })
              .map((geo) => {
                const cur = data.find((s) => s.countyCode === geo.id);
                const centroid = geoCentroid(geo);
                return (
                  <>
                    <Geography
                      fill={cur ? colorScale(cur[colorKey]) : "#5D7092"}
                      stroke="white"
                      key={geo.rsmKey + "-name"}
                      geography={geo}
                      onMouseEnter={() => {
                        setTooltipContent(`${geo.properties.name} — ${"7987"}`);
                        // console.log(geo)
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                      style={{
                        default: {
                          fill: "#5D7092",
                          outline: "none",
                        },
                        hover: {
                          fill: "rgb(52, 181, 191)",
                          outline: "none",
                        },
                        pressed: {
                          fill: "#E42",
                          outline: "none",
                        },
                      }}
                    />
                    {cur &&
                      (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                        <Marker coordinates={centroid}>
                          <text y="2" fontSize={14} textAnchor="middle">
                            {cur}
                          </text>
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={offsets[cur.id][0]}
                          dy={offsets[cur.id][1]}
                        >
                          <text x={4} fontSize={5} alignmentBaseline="middle">
                            {cur.id}
                          </text>
                        </Annotation>
                      ))}
                  </>
                );
              })
          }
        </Geographies>
      </ComposableMap> */}
    </>
  );
};

export default GeoDistNe;
