import React, { Component } from "react";
import { render } from "react-dom";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./styles.css";
import EmergencyDepartment from "./EmergencyDepartment";
import Inpatient from "./Inpatient";
import EdCovidHospitalizations from "./EdCovidHospitalization";
import IPCovidHospitalizations from "./IpCovidHospitalizations";

const GridLayout = WidthProvider(Responsive);

export default class AdtReflowLayout extends Component {
  state = {
    currentBreakpoint: "",
    layouts: storedLayouts,
  };

  static defaultProps = {
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    className: "layout",
    rowHeight: 50,
    isResizable: false,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    // compactType: "horizontal"
  };

  onLayoutChange = (layout, layouts) => {
    this.setState({ layouts });
  };

  onBreakpointChange = (breakpoint) => {
    this.setState({ currentBreakpoint: breakpoint });
  };

  render(props) {
    return (
      <main>
        <GridLayout
          {...this.props}
          layouts={this.state.layouts}
          onLayoutChange={(layout, layouts) =>
            this.onLayoutChange(layout, layouts)
          }
          onBreakpointChange={this.onBreakpointChange}
        >
          <div
            key="EmergencyDepartment"
            className="box"
            style={{ backgroundColor: "white" }}
            ref={this.reference}
          >
            <EmergencyDepartment data={this.props.data.emergencyDepartment} />
          </div>
          <div
            key="Inpatient"
            className="box"
            style={{ backgroundColor: "white" }}
          >
            <Inpatient data={this.props.data.inpatient} />
          </div>
          <div
            key="EdCovidHospitalizations"
            className="box"
            style={{ backgroundColor: "white" }}
          >
            <EdCovidHospitalizations
              data={this.props.data.emergencyDepartmentCovidHosp}
            />
          </div>
          <div
            key="IPCovidHospitalization"
            className="box"
            style={{ backgroundColor: "white" }}
          >
            <IPCovidHospitalizations
              data={this.props.data.inpatientCovidHosp}
            />
          </div>
        </GridLayout>
      </main>
    );
  }
}

const storedLayouts = {
  lg: [
    {
      i: "EmergencyDepartment",
      w: 6,
      h: 8,
      x: 0,
      y: 0,
      minH: 4,
      minW: 4,
      static: true,
    },
    { i: "Inpatient", w: 6, h: 8, x: 6, y: 0, minH: 6, minW: 4, static: true },
    {
      i: "EdCovidHospitalizations",
      w: 6,
      h: 8,
      x: 0,
      y: 0,
      minH: 6,
      minW: 3,
      static: true,
    },
    {
      i: "IPCovidHospitalization",
      w: 6,
      h: 8,
      x: 6,
      y: 0,
      minH: 6,
      minW: 3,
      static: true,
    },
  ],
  md: [
    {
      i: "EmergencyDepartment",
      w: 6,
      h: 8,
      x: 0,
      y: 0,
      minH: 4,
      minW: 4,
      static: true,
    },
    { i: "Inpatient", w: 6, h: 7, x: 6, y: 0, static: true },
    { i: "EdCovidHospitalizations", w: 6, h: 8, x: 0, y: 0, static: true },
    { i: "IPCovidHospitalization", w: 6, h: 7, x: 6, y: 0, static: true },
  ],
  sm: [
    { i: "EmergencyDepartment", w: 6, h: 7, x: 0, y: 0 },
    { i: "Inpatient", w: 6, h: 7, x: 6, y: 0 },
    { i: "EdCovidHospitalizations", w: 6, h: 7, x: 0, y: 0 },
    { i: "IPCovidHospitalization", w: 6, h: 7, x: 6, y: 0 },
  ],
  xs: [
    { i: "EmergencyDepartment", w: 4, h: 8, x: 0, y: 0 },
    { i: "Inpatient", w: 6, h: 8, x: 0, y: 0 },
    { i: "EdCovidHospitalizations", w: 4, h: 6, x: 0, y: 0 },
    { i: "IPCovidHospitalization", w: 4, h: 6, x: 0, y: 0 },
  ],
  xxs: [
    { i: "EmergencyDepartment", w: 4, h: 8, x: 0, y: 0 },
    { i: "Inpatient", w: 6, h: 8, x: 0, y: 0 },
    { i: "IPCovidHospitalization", w: 4, h: 6, x: 0, y: 0 },
    { i: "IPCovidHospitalization", w: 4, h: 6, x: 0, y: 0 },
  ],
};
