import React, { Component, useState, useEffect } from "react";
import "./index.css";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import HotelIcon from "@material-ui/icons/Hotel";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import Divider from "@material-ui/core/Divider";
import { useOktaAuth } from "@okta/okta-react";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import GroupIcon from "@material-ui/icons/Group";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 40,
    background: "#f5f7fa",
    padding: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  welcomeBlock: {
    flexGrow: 1,
    marginTop: 20,
    background: "#f5f7fa",
    padding: theme.spacing(0),
  },
  mobileWelcomeBlock: {
    flexGrow: 1,
    marginTop: 100,
    background: "#f5f7fa",
    padding: theme.spacing(0),
  },
  viewStyling: {
    padding: 30,
    fontFamily: "Arial",
    fontSize: 15,
    width: "100%",
  },
}));

function Menu() {
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");
  const [open, setOpen] = React.useState(true);
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  return (
    <>
      {isMobileDevice ? <div className={classes.mobileWelcomeBlock}>
        {userInfo &&
              userInfo.indexOf("Admins Nebraska Covid Dashboard") !== -1 ? (
                <a href="/admin">
                  <div className="box wrapper">
                    <h3>
                      <AdminPanelSettingsIcon fontSize="large"></AdminPanelSettingsIcon>
                      Admin Panel
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Bed Capacity Dashboard Users") !==
                -1 ? (
                <a href="/bedcapacity">
                  <div className="box wrapper">
                    <h3>
                      {" "}
                      <HotelIcon fontSize="large"></HotelIcon> Live Bed Capacity
                      Dashboard{" "}
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Labs Dashboard Users") !== -1 ? (
                <a href="/labs">
                  <div className="box wrapper">
                    <h3>
                      <LocalHospitalIcon fontSize="large"></LocalHospitalIcon>{" "}
                      Labs Dashboard
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska ADT Dashboard Users") !== -1 ? (
                <a href="/adtdashboard">
                  <div className="box wrapper">
                    <h3>
                      <BlurOnIcon fontSize="large"></BlurOnIcon> ADT Dashboard
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Covid19 Patients Dashboard Users") !==
                -1 ? (
                <a href="/covid19patients">
                  <div className="box wrapper">
                    <h3>
                      <GroupIcon fontSize="large"></GroupIcon> Covid19 Patients
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf(
                "Nebraska Capacity Forecasting Dashboard Users"
              ) !== -1 ? (
                <a href="/capacityforecasting">
                  <div className="box wrapper">
                    <h3>
                      <TrendingUpIcon fontSize="large"></TrendingUpIcon>
                      Capacity Forecasting
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska PPE Dashboard Users") !== -1 ? (
                <a href="/ppedashboard">
                  <div className="box wrapper">
                    <h3>
                      <AddLocationIcon fontSize="large"></AddLocationIcon> PPE
                      Dashboard
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Reports Dashboard Users") !== -1 ? (
                <a href="/reports">
                  <div className="box wrapper">
                    <h3>
                      <AssessmentIcon fontSize="large"></AssessmentIcon>Reports
                      Dashboards
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Ad-hoc Reports Dashboard Users") !==
                -1 ? (
                <a href="/adhocreports">
                  <div className="box wrapper">
                    <h3>
                      <EqualizerIcon fontSize="large"></EqualizerIcon> Ad Hoc
                      Reports
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}
      </div> :
      <div className={classes.content}>
        <div className={classes.toolbar}>
          <div className={classes.welcomeBlock}>
            <div className="box-container">
              {userInfo &&
              userInfo.indexOf("Admins Nebraska Covid Dashboard") !== -1 ? (
                <a href="/admin">
                  <div className="box wrapper">
                    <h3>
                      <AdminPanelSettingsIcon fontSize="large"></AdminPanelSettingsIcon>
                      Admin Panel
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Bed Capacity Dashboard Users") !==
                -1 ? (
                <a href="/bedcapacity">
                  <div className="box wrapper">
                    <h3>
                      {" "}
                      <HotelIcon fontSize="large"></HotelIcon> Live Bed Capacity
                      Dashboard{" "}
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Labs Dashboard Users") !== -1 ? (
                <a href="/labs">
                  <div className="box wrapper">
                    <h3>
                      <LocalHospitalIcon fontSize="large"></LocalHospitalIcon>{" "}
                      Labs Dashboard
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska ADT Dashboard Users") !== -1 ? (
                <a href="/adtdashboard">
                  <div className="box wrapper">
                    <h3>
                      <BlurOnIcon fontSize="large"></BlurOnIcon> ADT Dashboard
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Covid19 Patients Dashboard Users") !==
                -1 ? (
                <a href="/covid19patients">
                  <div className="box wrapper">
                    <h3>
                      <GroupIcon fontSize="large"></GroupIcon> Covid19 Patients
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf(
                "Nebraska Capacity Forecasting Dashboard Users"
              ) !== -1 ? (
                <a href="/capacityforecasting">
                  <div className="box wrapper">
                    <h3>
                      <TrendingUpIcon fontSize="large"></TrendingUpIcon>
                      Capacity Forecasting
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska PPE Dashboard Users") !== -1 ? (
                <a href="/ppedashboard">
                  <div className="box wrapper">
                    <h3>
                      <AddLocationIcon fontSize="large"></AddLocationIcon> PPE
                      Dashboard
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Reports Dashboard Users") !== -1 ? (
                <a href="/reports">
                  <div className="box wrapper">
                    <h3>
                      <AssessmentIcon fontSize="large"></AssessmentIcon>Reports
                      Dashboards
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}

              {userInfo &&
              userInfo.indexOf("Nebraska Ad-hoc Reports Dashboard Users") !==
                -1 ? (
                <a href="/adhocreports">
                  <div className="box wrapper">
                    <h3>
                      <EqualizerIcon fontSize="large"></EqualizerIcon> Ad Hoc
                      Reports
                    </h3>
                  </div>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
}
    </>
  );
}

export default Menu;
