import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/charts";

function ChronicConditionsByRace() {
  const [data, setData] = useState([]);
  useEffect(() => {
    asyncFetch();
  }, []);
  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/antfincdn/8elHX%26irfq/stack-column-data.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };
  var config = {
    data: data,
    isStack: true,
    height: 350,
    xField: "year",
    yField: "value",
    seriesField: "type",
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  };
  return (
    <div>
      <h5 style={{ textAlign: "center" }}> Chronic Conditions By Race</h5>

      <Column {...config} />
    </div>
  );
}

export default ChronicConditionsByRace;
