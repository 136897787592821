import React, { useMemo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import "./LiveBedCapacity.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import "./Table.css";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FaHospitalAlt } from "react-icons/fa";
import { hospitalTypes } from "./BedCapacityConstants";
import {
  FormControl,
  InputLabel,
  TextField,
  Box,
  Chip,
} from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import ProgressBar from "../../services/ProgressBar";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "rgb(8,87,97)",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
  },
});
const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "250px",
    },
  },
};
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}
const MultipleFilter = (rows, filler, filterValue) => {
  const arr = [];
  rows.forEach((val) => {
    if (filterValue.includes(val.original.HOSPITAL_TYPE)) arr.push(val);
  });
  return arr;
};

export default function SpecialtyBedsTable({ data }) {
  const [filterInput, setFilterInput] = useState("");
  const classes = useStyles();
  const [hospitalTypeFilterInput, setHospitalTypeFilterInput] = useState([
    "Childrens Hospital ",
    "Critical Access Hospital",
    "Short Term Acute Care Hospital",
    "Rehabilitation Hospital",
  ]);
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isTabDevice = useMediaQuery({
    query: "(max-device-width: 1150px) ",
  });
  const isAllHospitalTypeSelected =
    hospitalTypes.length > 0 &&
    hospitalTypeFilterInput?.length === hospitalTypes.length;

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("HOSPITAL_NAME", value);
    setFilterInput(value);
  };

  const handleHospitalTypeChange = (e) => {
    const value = e.target.value || undefined;
    if (value[value.length - 1] === "all") {
      setHospitalTypeFilterInput(
        hospitalTypeFilterInput?.length === hospitalTypes.length
          ? []
          : hospitalTypes
      );
      setFilter(
        "HOSPITAL_TYPE",
        hospitalTypeFilterInput?.length === hospitalTypes.length
          ? []
          : hospitalTypes
      );
      return;
    }
    setFilter("HOSPITAL_TYPE", value);
    setHospitalTypeFilterInput(value);
  };

  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: "Hospital Name",
        accessor: "HOSPITAL_NAME",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return <strong>{value}</strong>;
        },
        // First group columns
      },
      {
        // first group - TV Show
        Header: "Hospital Type",
        accessor: "HOSPITAL_TYPE",
        disableFilters: true,
        filter: MultipleFilter,
        // First group columns
      },
      {
        // first group - TV Show
        Header: "Burn",
        tipText: `Designated burn recovery beds`,
        // First group columns
        columns: [
          {
            Header: "Total Staffed Beds*",
            accessor: "Staffed Burn Beds",
            disableFilters: true,
          },
          {
            Header: "Total Occupied Beds",
            accessor: "Occupied Burn Beds",
            disableFilters: true,
          },
          {
            Header: "Total Beds Available",
            accessor: "Available Burn Beds",
            disableFilters: true,
          },
          {
            Header: "Percentage Occupied",
            accessor: "Percentage Occupied Burn Beds",
            disableFilters: true,
            Cell: ({ cell: { value } }) => {
              if (value == "-" || value == null) {
                return "-";
              } else if (Math.floor(value) >= 80 && Math.floor(value) <= 100) {
                const pValueER = Math.round(value);
                return (
                  <ProgressBar
                    bgcolor="#FF7F7F"
                    completed={pValueER}
                  ></ProgressBar>
                );
              } else if (Math.floor(value) > 100) {
                const pValueER = Math.round(value);
                return (
                  <>
                    <ProgressBar
                      bgcolor="#FF7F7F"
                      completed={pValueER}
                    ></ProgressBar>
                  </>
                );
              } else if (Math.floor(value) >= 60 && Math.floor(value) < 80) {
                const pValueER = Math.round(value);
                return (
                  <>
                    <ProgressBar
                      bgcolor="#F2E34C"
                      completed={pValueER}
                    ></ProgressBar>
                  </>
                );
              } else {
                const pValueER = Math.round(value);
                return (
                  <>
                    <ProgressBar
                      bgcolor="#9AE6B4"
                      completed={pValueER}
                    ></ProgressBar>
                  </>
                );
              }
            },
          },
        ],
      },
      {
        // Second group - Details
        Header: "Airborne Infection Isolation",
        tipText: `Rooms designed to prevent the spread of airborne infectious disease.`,
        // Second group columns
        columns: [
          {
            Header: "Total Staffed Beds*",
            accessor: "Staffed Airborn Infection Beds",
            disableFilters: true,
          },
          {
            Header: "Total Occupied Beds",
            accessor: "Occupied Airborne Infection Beds",
            disableFilters: true,
          },
          {
            Header: "Total Beds Available",
            accessor: "Available Airborne Infection Beds",
            disableFilters: true,
          },
          {
            Header: "Percentage Occupied",
            accessor: "Percentage Occupied Airborne Infection Beds",
            disableFilters: true,
            Cell: ({ cell: { value } }) => {
              if (value == "-" || value == null) {
                return "-";
              } else if (Math.floor(value) >= 80 && Math.floor(value) <= 100) {
                const pValueER = Math.round(value);
                return (
                  <ProgressBar
                    bgcolor="#FF7F7F"
                    completed={pValueER}
                  ></ProgressBar>
                );
              } else if (Math.floor(value) > 100) {
                const pValueER = Math.round(value);
                return (
                  <>
                    <ProgressBar
                      bgcolor="#FF7F7F"
                      completed={pValueER}
                    ></ProgressBar>
                  </>
                );
              } else if (Math.floor(value) >= 60 && Math.floor(value) < 80) {
                const pValueER = Math.round(value);
                return (
                  <>
                    <ProgressBar
                      bgcolor="#F2E34C"
                      completed={pValueER}
                    ></ProgressBar>
                  </>
                );
              } else {
                const pValueER = Math.round(value);
                return (
                  <>
                    <ProgressBar
                      bgcolor="#9AE6B4"
                      completed={pValueER}
                    ></ProgressBar>
                  </>
                );
              }
            },
          },
        ],
      },
      {
        // Third group - Details
        Header: "Psychiatric",
        tipText: `Inpatient beds used for Pediatrics, Adolescent, Adult, or Geriatrics psychiatric care.`,
        // Third group columns
        columns: [
          {
            Header: "Total Staffed Beds*",
            accessor: "Staffed Psychiatric Beds",
            disableFilters: true,
          },
          {
            Header: "Total Occupied Beds",
            accessor: "Occupied Psychiatric Beds",
            disableFilters: true,
          },
          {
            Header: "Total Beds Available",
            accessor: "Available Psychiatric Beds",
            disableFilters: true,
          },
          {
            Header: "Percentage Occupied",
            accessor: "Percentage Occupied Psychiatric Beds",
            disableFilters: true,
            Cell: ({ cell: { value } }) => {
              if (value == "-" || value == null) {
                return "-";
              } else if (Math.floor(value) >= 80 && Math.floor(value) <= 100) {
                const pValueER = Math.round(value);
                return (
                  <ProgressBar
                    bgcolor="#FF7F7F"
                    completed={pValueER}
                  ></ProgressBar>
                );
              } else if (Math.floor(value) > 100) {
                const pValueER = Math.round(value);
                return (
                  <>
                    <ProgressBar
                      bgcolor="#FF7F7F"
                      completed={pValueER}
                    ></ProgressBar>
                  </>
                );
              } else if (Math.floor(value) >= 60 && Math.floor(value) < 80) {
                const pValueER = Math.round(value);
                return (
                  <>
                    <ProgressBar
                      bgcolor="#F2E34C"
                      completed={pValueER}
                    ></ProgressBar>
                  </>
                );
              } else {
                const pValueER = Math.round(value);
                return (
                  <>
                    <ProgressBar
                      bgcolor="#9AE6B4"
                      completed={pValueER}
                    ></ProgressBar>
                  </>
                );
              }
            },
          },
        ],
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{ id: "occupiedBeds", desc: true }],
      },
    },

    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div>
      <div className={isMobileDevice ? "" : isTabDevice ? "" : "occupancyBox"}>
        <h6 className="card-header bg-light">
          <ListItem className={classes.root}>
            <ListItemIcon>
              <FaHospitalAlt size={25} className={classes.icon} />
            </ListItemIcon>
            <ListItemText disableTypography className={classes.text}>
              OCCUPANCY BY HOSPITAL
            </ListItemText>
          </ListItem>{" "}
        </h6>
        <div
          style={{
            display: "flex",
            flexDirection: isMobileDevice ? "column" : "row",
            margin: "10px",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontWeight: "bolder",
              marginRight: "10px",
              paddingTop: "15px",
            }}
          >
            Filter By Name:{" "}
          </span>

          <TextField
            style={{ width: 160, margin: 2.5, color: "black" }}
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            id="filled-search-1"
            type="search"
            label="Hospital name"
            variant="filled"
            size="small"
            value={filterInput}
            onChange={handleFilterChange}
          />

          <span
            style={{
              fontSize: "14px",
              fontWeight: "bolder",
              marginLeft: "10px",
              marginRight: "10px",
              paddingTop: "15px",
            }}
          >
            Filter By Type:{" "}
          </span>
          <FormControl
            className="filter-group"
            id="demo-multiple-name-label"
            variant="outlined"
            size="small"
            sx={{
              minWidth: 350,
              width: isMobileDevice ? "200px" : "700px",
              margin: 2.5,
              backgroundColor: "white",
            }}
          >
            <Select
              variant="outlined"
              size="small"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip-label"
              style={{ backgroundColor: "white", marginTop: "8px" }}
              displayEmpty
              multiple={true}
              value={hospitalTypeFilterInput || []}
              MenuProps={MenuProps}
              onChange={(event) => handleHospitalTypeChange(event)}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 3,
                    width: isMobileDevice ? "200px" : "700px",
                  }}
                >
                  {selected.map((value) => (
                    <Chip size="small" key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              <MenuItem value="all">
                <Checkbox checked={isAllHospitalTypeSelected} />
                <ListItemText primary="Select All" />
              </MenuItem>
              {hospitalTypes.map((c) => {
                return (
                  <MenuItem key={c} value={c}>
                    {" "}
                    <Checkbox
                      checked={hospitalTypeFilterInput.indexOf(c) > -1}
                    />
                    <ListItemText primary={c} />{" "}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {headerGroups.map((headerGroup) => (
          <span {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <span {...column.getHeaderProps()}>
                {column.canFilter ? (
                  <span> {column.render("Filter")}</span>
                ) : null}
              </span>
            ))}
          </span>
        ))}
      </div>
      <div className={isTabDevice ? "tableWrap" : ""}>
        <table className="specialty" {...getTableProps()}>
          <thead id={isTabDevice ? "" : isMobileDevice ? "" : "sticky-thc"}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    id="new-tooltip"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }
                  >
                    {column.render("Header")}
                    {headerGroup.headers[index].tipText && (
                      <span>{headerGroup.headers[index].tipText}</span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
