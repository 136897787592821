import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import data from "../../../../../public/labsMockData.json";

export default function OrderedVsCompleted({ data }) {
  // const convertDate = (time) => {
  //   const date = new Date(time);
  //   const newDate = date.getTime();
  //   return newDate;
  // };
  // const updateData = data.map(({ LabsCompleted, Labsordered, date }) => {
  //   return {
  //     LabsCompleted: LabsCompleted,
  //     Labsordered: Labsordered,
  //     date: convertDate(date),
  //   };
  // });
  const data1 = data.sort((a, b) => a.date - b.date);
  const result1 = data1.map(({ Labsordered, date }) => [
    parseFloat(date + "000"),
    parseFloat(Labsordered),
  ]);
  const result2 = data1.map(({ LabsCompleted, date }) => [
    parseFloat(date + "000"),
    parseFloat(LabsCompleted),
  ]);

  const options = {
    chart: {
      zoomType: "x",
    },

    rangeSelector: {
      buttons: [
        {
          type: "week",
          count: 1,
          text: "1w",
        },
        {
          type: "week",
          count: 2,
          text: "2w",
        },
        {
          type: "month",
          count: 1,
          text: "1m",
        },
        {
          type: "all",
          count: 1,
          text: "All",
        },
      ],

      selected: 0,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      tickInterval: 100,
      opposite: false,
    },
    xAxis: {
      minRange: 3600 * 1000 * 24 * 7, // one day
    },

    series: [
      {
        name: "Labs Ordered",
        data: result1,
        color: "rgb(52, 181, 191)",
        tooltip: {
          valueDecimals: 0,
        },
        marker: {
          enabled: true,
          radius: 3,
        },
      },
      {
        name: "Labs Completed",
        data: result2,
        color: "#5D7092",
        tooltip: {
          valueDecimals: 0,
        },
        marker: {
          enabled: true,
          radius: 4,
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
    </div>
  );
}
