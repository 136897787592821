import React, { Component } from "react";
import "./styles.css";
import { Grid } from "@mui/material";
import LabsOrderedVsCompleted from "./LabsOrderedVsCompleted";
import LabsTotalPatsTestedPositive from "./LabsTotalPatsTestedPositive";
import { Box } from "@material-ui/core";

export default class MyFirstGrid extends Component {
  render(props) {
    return (
      <main>
        <Box sx={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={6}>
              <div
                className="card-labs"
                style={{
                  backgroundColor: "white",
                  margin: "10px",
                  padding: "5px",
                }}
              >
                <LabsOrderedVsCompleted
                  data={this.props.data}
                  dataType={this.props.dataType}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div
                className="card-labs"
                style={{
                  backgroundColor: "white",
                  margin: "10px",
                  padding: "5px",
                }}
              >
                <LabsTotalPatsTestedPositive
                  data={this.props.data}
                  dataType={this.props.dataType}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}
        ></Box>
      </main>
    );
  }
}
