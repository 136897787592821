import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const EthnicityDist = ({ data }) => {
  const { HispanicLatino, NonHispanicLatino } = data;

  const newData = [
    ["Hispanic Latino", Math.floor(HispanicLatino * 100)],
    ["Non-Hispanic Latino", Math.floor(NonHispanicLatino * 100)],
  ];

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },

    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    xAxis: {
      type: "category",
      labels: {
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Average",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: "%",
    },
    series: [
      {
        name: "Average",
        data: newData || "",
        color: "rgb(52, 181, 191)",
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default EthnicityDist;
