import React, { useState, useRef } from "react";
import HotelIcon from "@material-ui/icons/Hotel";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import "./LiveBedCapacity.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { FaHospitalAlt } from "react-icons/fa";
import ProgressBar from "../../services/ProgressBar";
import ProgressBarTotals from "../../services/ProgressBarTotals";
import { useEffect } from "react";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "black",
  },
  text: {
    marginLeft: "-20px",
    color: "rgb(8,87,97)",
    fontStyle: "bold",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
    fontSize: "25px",
  },
});

function TotalAvailableBeds({ data, beds, type }) {
  const classes = useStyles();
  const [totalStaffedBeds, setTotalStaffedBeds] = useState("");
  const [totalOccupiedBeds, setTotalOccupiedBeds] = useState("");
  const [totalBedsAvailable, setTotalBedsAvailable] = useState("");
  const [totalPercentage, setTotalPercentage] = useState("");

  const calculate = (beds, type) => {
    const sumOfStaffedBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        return parseInt(accumulator) + parseInt(object[`Staffed ${type}`]);
      }, 0);
    // console.log(sumOfStaffedBeds, "sum1");
    setTotalStaffedBeds(sumOfStaffedBeds);

    const sumOfOccupieddBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        return parseInt(accumulator) + parseInt(object[`Occupied ${type}`]);
      }, 0);

    setTotalOccupiedBeds(sumOfOccupieddBeds);

    const sumOfAvailableBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        return parseInt(accumulator) + parseInt(object[`Available ${type}`]);
      }, 0);

    setTotalBedsAvailable(sumOfAvailableBeds);

    const percentageOfSums = (sumOfOccupieddBeds / sumOfStaffedBeds) * 100;
    setTotalPercentage(percentageOfSums);
  };

  useEffect(() => {
    calculate(beds, type);
  }, [data, beds, type]);

  const percentageValueEditor = (str) => {
    const value = parseFloat(str);
    if (str === null) {
      return "-";
    } else if (Math.floor(value) >= 80 && Math.floor(value) <= 100) {
      const pValueER = Math.round(value);
      return (
        <ProgressBarTotals
          bgcolor="#FF7F7F"
          completed={pValueER}
        ></ProgressBarTotals>
      );
    } else if (Math.floor(value) > 100) {
      const pValueER = Math.round(value);
      return (
        <>
          {" "}
          <ProgressBarTotals
            bgcolor="#FF7F7F"
            completed={pValueER}
          ></ProgressBarTotals>
        </>
      );
    } else if (Math.floor(value) >= 60 && Math.floor(value) < 80) {
      const pValueER = Math.round(value);
      return (
        <ProgressBarTotals
          bgcolor="#F2E34C"
          completed={pValueER}
        ></ProgressBarTotals>
      );
    } else {
      const pValueER = Math.round(value);
      return (
        <ProgressBarTotals
          bgcolor="#9AE6B4"
          completed={pValueER}
        ></ProgressBarTotals>
      );
    }
  };

  return (
    <div className="sticky-thc-totals">
      {/* Total beds card */}
      <h6 className="card-header bg-light">
        <ListItem className={classes.root}>
          <ListItemIcon>
            <HotelIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.text}>
            TOTAL AVAILABLE BEDS
          </ListItemText>
        </ListItem>{" "}
      </h6>{" "}
      <div className="row pt-4 pb-4">
        <div className="col-sm-3">
          {/* <Example/> */}
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {totalStaffedBeds ? totalStaffedBeds : 0}
              </h2>
              <Tooltip
                title={
                  <h6 style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                    When staffed beds are not available, licensed beds are used
                    for the counts.
                  </h6>
                }
                arrow
              >
                <p className="card-text text-muted">
                  Total Staffed Beds
                  <strong
                    style={{ color: "black", textDecoration: "underline" }}
                  >
                    *
                  </strong>
                </p>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {totalOccupiedBeds ? totalOccupiedBeds : 0}
              </h2>
              <p className="card-text text-muted">Total Occupied Beds</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {totalBedsAvailable ? totalBedsAvailable : 0}
              </h2>
              <p className="card-text text-muted">Total Beds Available</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {totalPercentage ? percentageValueEditor(totalPercentage) : 0}
              </h2>
              <p className="card-text text-muted">Percentage Occupied</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalAvailableBeds;
