import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import HotelIcon from "@material-ui/icons/Hotel";
import SearchIcon from "@material-ui/icons/Search";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { FaHospitalAlt } from "react-icons/fa";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "rgb(8,87,97)",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
  },
});

export default function AdtOccupancyByHospital({ columns, data }) {
  const [filterInput, setFilterInput] = useState("");
  const classes = useStyles();

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("hospitalName", value);
    setFilterInput(value);
  };

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
    },

    useFilters,
    useSortBy
  );

  return (
    <div>
      <h6 className="card-header bg-light">
        <ListItem className={classes.root}>
          <ListItemIcon>
            <FaHospitalAlt size={25} className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.text}>
            OCCUPANCY BY HOSPITAL
          </ListItemText>
        </ListItem>{" "}
      </h6>

      <div className="form-group has-search">
        <span className="fa fa-search form-control-feedback"></span>

        <input
          type="search"
          className="form-control"
          value={filterInput}
          onChange={handleFilterChange}
          placeholder="Search by Hospital Name"
          style={{ width: "13%", height: "35px", marginTop: "20px" }}
        ></input>
      </div>

      <table id="sticky-thc" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
