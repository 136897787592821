import React from "react";
import { Routes, Route } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./SecureRoute";
import LiveBedCapacity from "../components/pages/livebedcapacity/LiveBedCapacity";
import Pages from "../components/layout/Pages";
import Labs from "../components/pages/labs/Labs";
import Menu from "../components/pages/menu";
import Adt from "../components/pages/adt/Adt";
import Covid19 from "../components/pages/covid19/Covid19";
import Capacity from "../components/pages/capacityforecasting/Capacity";
import Ppe from "../components/pages/ppedashboard/Ppe";
import Reports from "../components/pages/reports/Reports";
import AdHocReports from "../components/pages/adhocreports/AdHocReports";
import Profile from "../Profile";
import NotFound from "../components/Alerts/NotFound";
import AdminAuth from "../components/adminPanel/AdminAuth";
import ContactUs from "../components/pages/Support/ContactUs";
import LabsSummaryCopy from "../components/pages/labsSummaryCopy/LabsSummary";
import Support from "../components/pages/Support/Support";


const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Pages>
            <LoginCallback />
          </Pages>
        }
      />
      <Route path="login/callback" element={<LoginCallback />} />
      <Route path="/bedcapacity" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <LiveBedCapacity />
            </Pages>
          }
        />
      </Route>
      <Route path="/labs" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <LabsSummaryCopy />
            </Pages>
          }
        />
      </Route>
      <Route path="/menu" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Menu />
            </Pages>
          }
        />
      </Route>
      <Route path="/adtdashboard" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Adt />
            </Pages>
          }
        />
      </Route>
      <Route path="/covid19patients" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Covid19 />
            </Pages>
          }
        />
      </Route>
      <Route path="/capacityforecasting" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Capacity />
            </Pages>
          }
        />
      </Route>
      <Route path="/ppedashboard" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Ppe />
            </Pages>
          }
        />
      </Route>
      <Route path="/reports" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Reports />
            </Pages>
          }
        />
      </Route>
      <Route path="/adhocreports" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <AdHocReports />
            </Pages>
          }
        />
      </Route>
      <Route path="/profile" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Profile />
            </Pages>
          }
        />
      </Route>
      <Route path="/notFound" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <NotFound />
            </Pages>
          }
        />
      </Route>
      <Route path="/admin" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <AdminAuth />
            </Pages>
          }
        />
      </Route>
      {/* <Route path="/contactus" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Support/>
            </Pages>
          }
        />
      </Route> */}
      {/* <Route path="/labssummarycopy" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
               <Labs />
            </Pages>
          }
        />
      </Route> */}
    </Routes>
  );
};

export default AppRoutes;
