import React, { useState, useEffect } from "react";
import { BidirectionalBar } from "@ant-design/charts";

export default function CovidAgeGenderDist() {
  var data = [
    {
      country: "Above-65",
      Male: 13.4,
      Female: 12.3,
    },
    {
      country: "51-65",
      Male: 14.4,
      Female: 6.3,
    },
    {
      country: "31-40",
      Male: 18.4,
      Female: 8.3,
    },
    {
      country: "19-30",
      Male: 34.4,
      Female: 13.8,
    },
    {
      country: "Below 18",
      Male: 44.4,
      Female: 19.5,
    },
    {
      country: "Unknown",
      Male: 24.4,
      Female: 18.8,
    },
  ];
  var config = {
    data: data,
    height: 350,
    xField: "country",
    xAxis: { position: "bottom" },
    color: ["rgb(52, 181, 191)", "#5D7092"],
    interactions: [{ type: "active-region" }],
    yField: ["Male", "Female"],
    tooltip: {
      shared: false,
      showMarkers: true,
    },
  };

  return (
    <div>
      <h5 style={{ textAlign: "center" }}> Age and Gender Distribution </h5>
      <BidirectionalBar {...config} />
    </div>
  );
}
