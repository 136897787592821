import React from "react";

export default function TopSixCounties({ data }) {
  const sixCounties = data.sort(
    (a, b) =>
      parseFloat(b.PatientsTestedPositivePerCounty) -
      parseFloat(a.PatientsTestedPositivePerCounty)
  );

  return (
    <div>
      <table
        style={{ border: "none", height: "100px" }}
        className="table table-hover"
      >
        <thead style={{ backgroundColor: "#5D7092" }}>
          <tr>
            <th scope="col">County</th>
            <th scope="col">Positive Cases</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {sixCounties.map((w) => {
            return (
              <tr>
                <td style={{ textAlign: "center" }}> {w.County}</td>
                <td> {parseInt(w.PatientsTestedPositivePerCounty)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}