import React, { useMemo, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import HighRiskPop from "./HighRiskPop";
import MapGeoDistNe from "./capacityForecastingCharts/MapGeoDistNe";
import Forecasting from "./capacityForecastingCharts/Forecasting";
import { useOktaAuth } from "@okta/okta-react";
import NotFound from "../../Alerts/NotFound";


const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "rgb(8,87,97)",
  },
  text: {
    marginLeft: "-20px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
  },
});

export default function Capacity() {
  const [highRiskTableData, setHighRiskTableData] = useState([]);
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const getData = () => {
    (async () => {
      const result = await fetch("capacityTestData.json", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const names = await result.json();
      setHighRiskTableData(names);
      //  setBedsData(names.total_hospital_summary);
      // console.log(names.per_hospital_summary)
    })();
  };

  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };

  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
      updateTime();
    }, 100000);

    return () => clearInterval(interval);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "County Name",
        accessor: "countyName",
      },
      {
        Header: "Deaibates",
        accessor: "deaibates",
      },
      {
        Header: "Cardiac",
        accessor: "cardiac",
      },
      {
        Header: "Home Ventilations",
        accessor: "homeVentilations",
      },
      {
        Header: "Immune Compromised",
        accessor: "immuneCompromised",
      },
      {
        Header: "COPD & Chronic Respiratory",
        accessor: "copdChronicResp",
      },
      {
        Header: "Total Count",
        accessor: "totalCount",
      },
    ],
    []
  );

  if (handleGroupAuth("Nebraska Capacity Forecasting Dashboard Users")) {
  return (
    <div>
      <div className="mt-5 pt-4">
        <ListItem className={classes.root}>
          <ListItemIcon>
            <TrendingUpIcon fontSize="large" className={classes.icon} />
          </ListItemIcon>
          <ListItemText disableTypography>
            <span className={classes.text}>Capacity Forecasting Dashboard</span>
            <div className="d-inline">
              {/* <span className="livebadge pulsate ml-2">Live</span>    */}
              <span className=" ml-3"> Last Updated : {updateTime()}</span>
            </div>
          </ListItemText>
        </ListItem>
      </div>
      <Paper elevation={0} style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Forecasting />
      </Paper>

      <Paper elevation={0} style={{ padding: "10px", marginBottom: "20px" }}>
        <HighRiskPop columns={columns} data={highRiskTableData} />
      </Paper>
      <Paper elevation={0} style={{ padding: "10px", marginBottom: "20px" }}>
        <MapGeoDistNe />
      </Paper>
      <div className="alert alert-light mt-4" role="alert">
        <p>
          Authorized users agree information contained within the CyncHealth
          dashboards is confidential and agree to not disseminate such
          information contained therein.
        </p>
        <p>
          <strong>
            {" "}
            Table. High Risk Population by Disease/Drug Category and by County
            in Nebraska
          </strong>
        </p>
        Data Source : Chronic Condition categories based on drug classes using
        prescription drug dispense data from Nebraska PDMP 2019-YTD data and
        based on till date as of 06/28/2020.
        <br />
        Disclaimer: Disclosure of identifiable health information is made
        pursuant to Nebraska Executive Order No. 20-15 and the federal HHS{" "}
        <a href="https://www.hhs.gov/sites/default/files/notification-enforcement-discretion-hipaa.pdf?language=es">
          Notification of Enforcement Discretion under HIPAA{" "}
        </a>
        , as such use is made to individuals who are in a position to prevent
        and lessen a serious and imminent threat to the health and safety of a
        person or the public. <br></br> <br></br>
        <p>
          <strong>
            {" "}
            Figure. Covid19 Total Beds, ICU and Ventilators Forecasting Model by
            County
          </strong>
        </p>
        Data Source : Institute for Health Metrics and Evaluation (iHME)
        Covid-19 Projections. State-level Projections are available{" "}
        <a
          href="https://covid19.healthdata.org/united-states-of-america"
          target="_blank"
        >
          here
        </a>
        . Nebraska State Level data downloaded as of 06/22/2021.
        <br />
        Notes : County-level Projections estimated based on the county's
        population size as of the state total population. 2019 Population
        estimates obtained from the U.S. Census Bureau available at{" "}
        <a
          href="https://www.census.gov/data/datasets/time-series/demo/popest/2010s-counties-total.html"
          target="_blank"
        >
          {" "}
          https://www.census.gov/data/datasets/time-series/demo/popest/2010s-counties-total.html{" "}
        </a>
        <br />
        <br />
        <p>
          {" "}
          <strong>
            For More Information on iHME Forecasting model, citation made
            available here{" "}
          </strong>
          : IHME COVID-19 health service utilization forecasting team.
          Forecasting COVID-19 impact on hospital bed-days, ICU-days, Ventilator
          days and deaths by US state in next 4 months. MedRxiv. 26 March 2020.
          Available at{" "}
          <a
            href="http://healthdata.org/sites/default/files/files/research_articles/2020/covid_paper_MEDRXIV-2020-043752v1-Murray.pdf"
            target="_blank"
          >
            {" "}
            http://healthdata.org/sites/default/files/files/research_articles/2020/covid_paper_MEDRXIV-2020-043752v1-Murray.pdf{" "}
          </a>
        </p>
        <p>
          Disclaimer : Data Obtained from iHME is in compliance with iHME terms
          and conditions and was used, shared, modified or built upon by NEHII
          persuant to the{" "}
          <a href="#">
            {" "}
            Creative Commons Attribution-Noncommercial 4.0 International License
          </a>
          , including section-5 disclaimer of warranties and limitations of
          liability.
        </p>
      </div>
    </div>
  )} else {
    return (<NotFound/>);
  }
}
