import React, { useMemo, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import TotalCovidData from "./TotalCovidData";
import GroupIcon from "@mui/icons-material/Group";
import Covid19ReflowLayout from "./covid19Charts/Covid19ReflowLayout";
import { useOktaAuth } from "@okta/okta-react";
import NotFound from "../../Alerts/NotFound";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "rgb(8,87,97)",
  },
  text: {
    marginLeft: "-20px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "rgb(8,87,97)",
    marginLeft: "-10px",
  },
});

export default function Covid19() {
  const [data, setData] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const getData = () => {
    (async () => {
      const result = await fetch("covidTestData.json", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const names = await result.json();
      setData(names.covid19Patients);
      //  setBedsData(names.total_hospital_summary);
      // console.log(names.per_hospital_summary)
    })();
  };

  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
      updateTime();
    }, 100000);

    return () => clearInterval(interval);
  }, []);

  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  if (handleGroupAuth("Nebraska Covid19 Patients Dashboard Users")) {
    return (
      <div>
        <div className="mt-5 pt-4">
          <ListItem className={classes.root}>
            <ListItemIcon>
              <GroupIcon fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText disableTypography>
              <span className={classes.text}>Covid-19 Patients Dashboard</span>
              <div className="d-inline">
                {/* <span className="livebadge pulsate ml-2">Live</span>    */}
                <span className=" ml-3"> Last Updated : {updateTime()}</span>
              </div>
            </ListItemText>
          </ListItem>
          <div className="alert alert-light mt-3" role="alert">
            Authorized users agree information contained within the CyncHealth's
            Dashboards is confidential and agree to not disseminate such
            information contained therein.
            <br></br>
            <br></br>
            Data Source : Confirmed cases and hospitalization data obtained from
            HIE and current as of 06/22/2021. HIE data includes data from
            CyncHealth's participants with execueted Participants agreement and
            Buisness Associate Agreements. Chronic Condition categories based on
            drug classes using prescription drug dispense data from Nebraska
            PDMP 2019-YTD data and based on till date as of 06/28/2020.
            <br></br>
            <br></br>
            Notes : "Recovered Patients" defined as covid-19 patients who are 30
            days out from thier confirmed lab result date or encounter date of
            diagnosis.
          </div>
        </div>
        <Paper elevation={0} style={{ padding: "10px", marginBottom: "20px" }}>
          <TotalCovidData data={data} />
        </Paper>
        <Covid19ReflowLayout />

        <div className="alert alert-light mt-4" role="alert">
          Disclaimer: Disclosure of identifiable health information is made
          pursuant to Nebraska Executive Order No. 20-15 and the federal HHS{" "}
          <a href="https://www.hhs.gov/sites/default/files/notification-enforcement-discretion-hipaa.pdf?language=es">
            Notification of Enforcement Discretion under HIPAA{" "}
          </a>
          , as such use is made to individuals who are in a position to prevent
          and lessen a serious and imminent threat to the health and safety of a
          person or the public. <br></br> <br></br>
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}
